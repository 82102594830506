<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->

    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div
        class="d-flex flex-column-fluid"
        v-disable-all="user.leitura == true"
      >
        <div class="container">
          <!--begin::Dashboard-->
          <!--begin::Row-->

          <div class="row">
            <div class="col-lg-12">
              <!--begin::Card-->
              <DadosPessoais
                :isEmpresa="true"
                :data="empresa"
                :ordem="1"
                @onAtualizar="atualizaDados"
              />
              <!--end::Card-->
              <!--begin::Card-->
              <div class="card card-custom gutter-b example example-compact">
                <div class="card-header">
                  <h3 class="card-title">2. Informações de Contato</h3>
                </div>
                <div class="card-body">
                  <div class="form-group row">
                    <Emails
                      @onAtualizar="atualizaEmails"
                      :dados="emails"
                      :disable="false"
                    />
                  </div>
                  <div class="form-group row">
                    <Endereco
                      :objeto="endereco"
                      @onAtualizar="atualizaEndereco"
                      :disable="false"
                    />
                  </div>
                  <div class="form-group row">
                    <Telefones
                      @onAtualizar="atualizaTelefones"
                      :dados="telefones"
                      :disable="false"
                    />
                  </div>
                </div>
              </div>
              <!--end::Card-->

              <!--begin::Card-->
              <div class="card card-custom gutter-b example example-compact">
                <ContaBancaria :ordem="3" :objeto="contaBancaria" />
                <div class="form-group row">
                  <div class="col-lg-6" style="margin-left: 30px">
                    <label>Status:</label> &nbsp;
                    <toggle-button
                      v-model="empresa.ativo"
                      :sync="true"
                      :labels="true"
                      :font-size="15"
                      :width="80"
                      :height="40"
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
              </div>

              <!--end::Form-->
              <div class="card-footer">
                <div class="row">
                  <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                    <button
                      type="button"
                      @click="manter()"
                      class="btn btn-primary mr-2"
                    >
                      {{ opcao == "A" ? "Adicionar" : "Atualizar" }}
                    </button>
                    <button
                      type="reset"
                      @click="voltar()"
                      class="btn btn-secondary"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Row-->

      <!--end::Row-->
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import ContaBancaria from "../../components/common/ContaBancaria";
import DadosPessoais from "../../components/common/DadosPessoais";
import Emails from "../../components/common/Emails";
import Endereco from "../../components/common/Endereco";
import Telefones from "../../components/common/Telefones";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
export default {
  name: "Empresa",
  data: () => ({
    emp: {
      tipo_servico: "",
      responsavel: "",
      optante_simples: false,
      ativo: true,
      logo: null,
      contasBancarias: [
        {
          banco: "",
          codigo_banco: "",
          agencia: "",
          dv_agencia: "",
          conta: "",
          dv_conta: "",
          tipo: "",
          ativo: true,
          isPoupanca: false,
        },
      ],
      tiposRecebimentos: [],
      formasPagamento: [],
      servicos: [],
      pessoa: {
        tipo_pessoa: "J",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
    },
    cb: {
      banco: "",
      codigo_banco: "",
      agencia: "",
      dv_agencia: "",
      conta: "",
      dv_conta: "",
      tipo: "",
      ativo: true,
      isPoupanca: false,
    },
    end: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      ativo: true,
    },
    buttons: [
      {
        link: "/empresa",
        titulo: "ver todas Empresas",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    opcao: "",
  }),
  computed: {
    ...mapState("auth", ["user"]),
    empresa: {
      get() {
        return this.emp;
      },
      set(nValue) {
        this.emp = nValue;
      },
    },
    telefones: {
      get() {
        return this.emp.pessoa.telefones;
      },
      set(nValue) {
        this.emp.pessoa.telefones = nValue;
      },
    },
    emails: {
      get() {
        return this.emp.pessoa.emails;
      },
      set(nValue) {
        this.emp.pessoa.emails = nValue;
      },
    },
    contaBancaria: {
      get() {
        return this.cb;
      },
      set(nValue) {
        this.cb = nValue;
      },
    },
    endereco: {
      get() {
        return this.end;
      },
      set(nValue) {
        this.end = nValue;
      },
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Empresa";
      } else {
        return "Editando Empresa";
      }
    },
    email() {
      return this.emails;
    },
  },
  mounted() {
    // this.contaBancaria = this.empresa.contasBancarias[0];
    // this.endereco = this.empresa.pessoa.enderecos[0];
    this.opcao = "A";

    if (this.$route.params.id != 0) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetEmpresa(this.$route.params.id).then((r) => {
        this.empresa = r;

        console.log(this.empresa);
        if (this.empresa.contasBancarias.length > 0) {
          this.contaBancaria = this.empresa.contasBancarias[0];
        } else {
          this.contaBancaria = {
            banco: "",
            codigo_banco: "",
            agencia: "",
            dv_agencia: "",
            conta: "",
            dv_conta: "",
            tipo: "",
            ativo: true,
            favorecido: "",
            identificador: "",
          };
        }

        if (this.empresa.pessoa.enderecos.length > 0) {
          this.endereco = this.empresa.pessoa.enderecos[0];
        } else {
          this.endereco = {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          };
        }
        window.KTApp.unblockPage();

        // this.emails = this.empresa.pessoa.emails;
        // this.telefones = this.empresa.pessoa.telefones;
      });
      // .catch((error) => {
      //   console.log(error);
      //   if (error.data) {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: error.data.error.title || "Oops...",
      //       text: error.data.error.message,
      //     });
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: "Ocorreu um erro inesperado, favor contactar o suporte.",
      //     });
      //   }
      // });
    }
  },
  methods: {
    ...mapActions("empresa", [
      "ActionGetEmpresa",
      "ActionCreateEmpresas",
      "ActionUpdateEmpresas",
      "ActionUploadLogo",
    ]),
    manter() {
      if (this.empresa.pessoa.tipo_pessoa == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Tipo é obrigatório",
        });
        return;
      }
      if (this.empresa.pessoa.tipo_pessoa == "J") {
        if (this.empresa.pessoa.nome == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Nome Fantasia é obrigatório",
          });
          return;
        }
        if (this.empresa.pessoa.razao_social == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Razão Social é obrigatório",
          });
          return;
        }
        if (this.empresa.responsavel == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Responsável é obrigatório",
          });
          return;
        }
        if (this.empresa.pessoa.identificador == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "CNPJ é obrigatório",
          });
          return;
        }
      } else {
        if (this.empresa.pessoa.identificador == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "CPF é obrigatório",
          });
          return;
        }
        if (this.empresa.pessoa.nome == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Nome é obrigatório",
          });
          return;
        }
        if (this.empresa.pessoa.rg == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "RG é obrigatório",
          });
          return;
        }
        if (this.empresa.pessoa.data_nascimento == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Data de nascimento é obrigatório",
          });
          return;
        }
      }

      try {
        let r;

        if (this.empresa.contasBancarias.length == 0) {
          this.empresa.contasBancarias = [];
          this.empresa.contasBancarias.push(this.contaBancaria);
        } else {
          this.empresa.contasBancarias[0] = this.contaBancaria;
        }
        if (!this.empresa.pessoa.enderecos) {
          this.empresa.pessoa.enderecos = [];
          this.empresa.pessoa.enderecos.push(this.endereco);
        } else {
          this.empresa.pessoa.enderecos[0] = this.endereco;
        }

        if (!this.empresa.tiposRecebimentos) {
          this.empresa.tiposRecebimentos = [];
        }
        if (!this.empresa.formasPagamento) {
          this.empresa.formasPagamento = [];
        }
        if (!this.empresa.servicos) {
          this.empresa.servicos = [];
        }
        // this.empresa.pessoa.emails = this.emails;
        // this.empresa.pessoa.telefones = this.telefones;
        // console.log(this.empresa);

        if (this.opcao == "A") {
          r = this.ActionCreateEmpresas(this.empresa);
        } else {
          r = this.ActionUpdateEmpresas(this.empresa);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });

        r.then((x) => {
          window.KTApp.unblockPage();
          if (x.sucesso == true) {
            if (this.empresa.logo != null) {
              this.ActionUploadLogo({
                logo: this.empresa.logo,
                id: x.empresa.id_empresa,
              }).then((r) => {
                this.$swal
                  .fire({
                    icon: r.sucesso == true ? "success" : "error",
                    title: r.sucesso == true ? `Sucesso` : "Atenção / Erro",
                    text:
                      r.sucesso == true
                        ? `Empresa ${
                            this.opcao == "A" ? "adicionada" : "atualizada"
                          } com sucesso`
                        : r.error.message,
                  })
                  .then(() => {
                    this.$router.push("/empresa");
                  });
              });
            } else {
              this.$swal
                .fire({
                  icon: x.sucesso == true ? "success" : "error",
                  title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                  text:
                    x.sucesso == true
                      ? `Empresa ${
                          this.opcao == "A" ? "adicionada" : "atualizada"
                        } com sucesso`
                      : x.error.message,
                })
                .then(() => {
                  this.$router.push("/empresa");
                });
            }
          }
        });
      } catch (error) {
        // console.log(error);
        // if (error.data) {
        //   console.log(error.data);
        //   this.$swal.fire({
        //     icon: "error",
        //     title: error.data.error.title || "Oops...",
        //     text: error.data.error.message,
        //   });
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        //   });
        // }
      }
    },
    voltar() {
      this.$router.push("/empresa");
    },
    atualizaEmails(item) {
      this.empresa.pessoa.emails = item;
    },
    atualizaTelefones(item) {
      this.empresa.pessoa.telefones = item;
    },
    atualizaDados(item) {
      this.empresa.pessoa = item;
    },
    atualizaEndereco(item) {
      this.endereco = item;
      this.empresa.pessoa.enderecos[0] = this.endereco;
    },
  },
  watch: {
    emails(nValue) {
      this.empresa.pessoa.emails = nValue;
      this.emails = nValue;
    },
    telefones(nValue) {
      this.empresa.pessoa.telefones = nValue;
      this.telefones = nValue;
    },
    empresa(nValue) {
      if (nValue) {
        this.empresa = nValue;
      }
    },
    contaBancaria(nValue) {
      if (nValue) {
        this.contaBancaria = nValue;
      }
    },
    endereco(nValue) {
      if (nValue) {
        this.empresa.pessoa.enderecos[0] = nValue;
      }
    },
  },
  components: {
    ContaBancaria,
    DadosPessoais,
    Endereco,
    Emails,
    Telefones,
    SubHeader,
  },
};
</script>