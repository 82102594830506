<template>
  <div>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <sub-header :titulo="empresaNome" :buttons="buttons"></sub-header>
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
          <!--begin::Dashboard-->
          <!--begin::Row-->
          <!--begin::Row-->
          <div class="row">
            <div class="col-xl-3">
              <!--begin::Stats Widget 29-->
              <div
                class="card card-custom bg-primary bgi-no-repeat card-stretch gutter-b"
              >
                <!--begin::Body-->
                <div class="card-body">
                  <span class="svg-icon svg-icon-2x svg-icon-white">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="13"
                          y="4"
                          width="3"
                          height="16"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="8"
                          y="9"
                          width="3"
                          height="11"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="18"
                          y="11"
                          width="3"
                          height="9"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="3"
                          y="13"
                          width="3"
                          height="7"
                          rx="1.5"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
                    >{{
                      dados.saldo.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}</span
                  >
                  <span class="font-weight-bold text-white font-size-sm"
                    >SALDO ATUAL</span
                  >
                </div>
                <!--end::Body-->
              </div>
              <!--end::Stats Widget 29-->
            </div>
            <div class="col-xl-3">
              <!--begin::Stats Widget 30-->
              <div
                class="card card-custom bg-danger bgi-no-repeat card-stretch gutter-b"
              >
                <!--begin::Body-->
                <div class="card-body">
                  <span class="svg-icon svg-icon-2x svg-icon-white">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="13"
                          y="4"
                          width="3"
                          height="16"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="8"
                          y="9"
                          width="3"
                          height="11"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="18"
                          y="11"
                          width="3"
                          height="9"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="3"
                          y="13"
                          width="3"
                          height="7"
                          rx="1.5"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
                    >{{
                      dados.aPagar.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}</span
                  >
                  <span class="font-weight-bold text-white font-size-sm"
                    >A PAGAR</span
                  >
                </div>
                <!--end::Body-->
              </div>
              <!--end::Stats Widget 30-->
            </div>
            <div class="col-xl-3">
              <!--begin::Stats Widget 31-->
              <div class="card card-custom bg-success card-stretch gutter-b">
                <!--begin::Body-->
                <div class="card-body">
                  <span class="svg-icon svg-icon-2x svg-icon-white">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="13"
                          y="4"
                          width="3"
                          height="16"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="8"
                          y="9"
                          width="3"
                          height="11"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="18"
                          y="11"
                          width="3"
                          height="9"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="3"
                          y="13"
                          width="3"
                          height="7"
                          rx="1.5"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
                    >{{
                      dados.aReceber.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}</span
                  >
                  <span class="font-weight-bold text-white font-size-sm"
                    >A RECEBER</span
                  >
                </div>
                <!--end::Body-->
              </div>
              <!--end::Stats Widget 31-->
            </div>
            <div class="col-xl-3">
              <!--begin::Stats Widget 32-->
              <div class="card card-custom bg-dark card-stretch gutter-b">
                <!--begin::Body-->
                <div class="card-body">
                  <span class="svg-icon svg-icon-2x svg-icon-white">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="13"
                          y="4"
                          width="3"
                          height="16"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="8"
                          y="9"
                          width="3"
                          height="11"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="18"
                          y="11"
                          width="3"
                          height="9"
                          rx="1.5"
                        />
                        <rect
                          fill="#000000"
                          x="3"
                          y="13"
                          width="3"
                          height="7"
                          rx="1.5"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 text-hover-primary d-block"
                    >{{
                      (dados.atrasado * -1).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}</span
                  >
                  <span class="font-weight-bold text-white font-size-sm"
                    >EM ATRASO</span
                  >
                </div>
                <!--end::Body-->
              </div>
              <!--end::Stats Widget 32-->
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-xxl-4">
              <!--begin::Mixed Widget 1-->
              <div class="card card-custom bg-gray-100 card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0 bg-danger py-5">
                  <h3 class="card-title font-weight-bolder text-white">
                    <img
                      src="assets/media/svg/icons/Shopping/Chart-pie.svg"
                    />&ensp; Evolução faturamento
                  </h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body p-0 position-relative overflow-hidden">
                  <apexchart
                    height="150"
                    type="area"
                    class="card-rounded-bottom bg-danger"
                    :options="optionsEvolucao"
                    :series="seriesEvolucao"
                  ></apexchart>

                  <!--end::Chart-->

                  <div
                    class="card card-custom card-stretch card-stretch-half gutter-b"
                    style="margin-top: 10px"
                  >
                    <!--begin::Body-->
                    <div class="card-body p-0">
                      <div
                        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                      >
                        <span
                          class="symbol symbol-50 symbol-light-success mr-2"
                        >
                          <span class="symbol-label">
                            <span class="svg-icon svg-icon-xl svg-icon-success">
                              <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24" />
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    x="7"
                                    y="4"
                                    width="10"
                                    height="4"
                                  />
                                  <path
                                    d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z"
                                    fill="#000000"
                                  />
                                </g>
                              </svg>
                              <!--end::Svg Icon-->
                            </span>
                          </span>
                        </span>
                        <div class="d-flex flex-column text-right">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-h3"
                            >{{
                              valorMesAtualOrcamento.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</span
                          >
                          <span class="text-muted font-weight-bold mt-2">
                            Orçamentos
                            {{ mesAtual }}
                          </span>
                        </div>
                      </div>
                      <!-- <div
                    id="kt_stats_widget_11_chart"
                    class="card-rounded-bottom"
                    data-color="success"
                    style="height: 150px"
                  ></div>-->
                      <apexchart
                        height="200"
                        type="area"
                        class="card-rounded-bottom"
                        data-color="success"
                        :options="OptionOrcamento"
                        :series="seriesOrcamento"
                      ></apexchart>
                      <div
                        align="right"
                        style="margin: 10px; margin-top: -95px"
                      >
                        <router-link to="/orcamento" style="color: #000">
                          <p style="font-size: 10px">
                            <img
                              src="assets/media/svg/icons/Text/Article.svg"
                            />
                            VER TODOS OS MESES
                          </p>
                        </router-link>
                      </div>
                    </div>
                    <!--end::Body-->
                  </div>
                </div>
                <!--end::Body-->
              </div>
              <!--end::Mixed Widget 1-->
            </div>

            <div class="col-lg-6 col-xxl-4 order-1 order-xxl-1">
              <!--begin::List Widget 1-->
              <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header align-items-center border-0 mt-4">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="font-weight-bolder text-dark">
                      <img
                        src="assets/media/svg/icons/Shopping/Chart-line2.svg"
                      />
                      Lançamentos do dia
                    </span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm"
                      >Saldo:
                      {{
                        valorExtrato.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}</span
                    >
                  </h3>
                  <div class="card-toolbar">
                    <div>
                      <i class="ki ki-bold-more-hor"></i>
                      <router-link to="/extrato" href style="color: #ccc">
                        <b style="font-size: 11px; color: ccc"> VER EXTRATO</b>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-4">
                  <div
                    class="timeline timeline-1 mt-3"
                    style="
                      overflow-y: scroll;
                      width: 357px;
                      height: 365px;
                      background-color: #fff;
                    "
                  >
                    <!--begin::Item-->
                    <div
                      v-for="(item, index) in dados.extrato"
                      :key="index"
                      class="timeline-item align-items-start"
                    >
                      <!--begin::Label-->
                      <div
                        class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                      >
                        {{ item.hora }}
                      </div>
                      <!--end::Label-->
                      <!--begin::Badge-->
                      <div class="timeline-badge">
                        <i
                          :class="`fa fa-genderless ${
                            item.tipo == 'R' ? 'text-primary' : 'text-danger'
                          } icon-xl`"
                        ></i>
                      </div>
                      <!--end::Badge-->
                      <!--begin::Text-->
                      <div
                        class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
                      >
                        {{ item.nome }}
                        <br />
                        <b
                          :class="`${
                            item.tipo == 'R' ? 'text-primary' : 'text-danger'
                          }`"
                          >{{
                            item.valor.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}</b
                        >.
                      </div>
                      <!--end::Text-->
                    </div>
                    <!--end::Item-->
                  </div>
                  <!--end: Items-->
                </div>
                <!--end: Card Body-->
              </div>
            </div>

            <div class="col-lg-6 col-xxl-4">
              <div class="row">
                <div class="col-xl-12">
                  <!--begin::Tiles Widget 11-->
                  <div
                    class="card card-custom bg-info gutter-b"
                    style="height: 200px"
                  >
                    <div class="card-body">
                      <span class="svg-icon svg-icon-3x svg-icon-white ml-n2">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                      <div
                        class="text-inverse-success font-weight-bolder font-size-h2 mt-3"
                      >
                        {{ dados.clientes }}
                      </div>
                      <router-link
                        to="/cliente"
                        class="text-inverse-success font-weight-bold font-size-lg mt-1"
                        >CLIENTES</router-link
                      >
                      <br />
                      <br />
                      <router-link to="/cliente" style="color: #000">
                        <p style="font-size: 10px">. VER TODOS</p>
                      </router-link>
                    </div>
                  </div>
                  <!--end::Tiles Widget 11-->
                </div>

                <div class="col-xl-6">
                  <!--begin::Tiles Widget 11-->
                  <div
                    class="card card-custom bg-info gutter-b"
                    style="height: 200px"
                  >
                    <div class="card-body">
                      <span class="svg-icon svg-icon-3x svg-icon-white ml-n2">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                      <div
                        class="text-inverse-success font-weight-bolder font-size-h2 mt-3"
                      >
                        {{ dados.fornecedores }}
                      </div>
                      <router-link
                        to="/fornecedores"
                        class="text-inverse-success font-weight-bold font-size-lg mt-1"
                        >FORNECEDORES</router-link
                      >
                      <br />
                      <br />
                      <router-link to="/cliente" style="color: #000">
                        <p style="font-size: 10px">. VER TODOS</p>
                      </router-link>
                    </div>
                  </div>
                  <!--end::Tiles Widget 11-->
                </div>

                <div class="col-xl-6">
                  <!--begin::Tiles Widget 12-->
                  <div
                    class="card card-custom bg-warning gutter-b opacity-80"
                    style="height: 200px"
                  >
                    <div class="card-body">
                      <span class="svg-icon svg-icon-3x svg-icon-white">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                      <div
                        class="text-inverse-success font-weight-bolder font-size-h2 mt-3"
                      >
                        {{ dados.funcionarios }}
                      </div>
                      <router-link
                        to="/funcionario"
                        href="#"
                        class="text-inverse-success font-weight-bold font-size-lg mt-1"
                        >FUNCIONÁRIOS</router-link
                      >
                      <br />
                      <br />
                      <router-link to="/funcionario" style="color: #000">
                        <p style="font-size: 10px">. VER TODOS</p>
                      </router-link>
                    </div>
                  </div>
                  <!--end::Tiles Widget 12-->
                </div>
              </div>
            </div>

            <div class="col-xxl-12 order-4 order-xxl-1">
              <!--begin::Advance Table Widget 2-->
              <div class="col-lg-13">
                <!--begin::Card-->
                <div class="card card-custom gutter-b">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">
                        <img
                          src="assets/media/svg/icons/Shopping/Chart-bar1.svg"
                        />
                        Fluxo financeiro Anual
                      </h3>
                    </div>

                    <!-- <div
                      class="checkbox-lg text-right col-lg-8"
                      style="margin-top: 30px"
                    >
                      <input
                        type="checkbox"
                        v-model="contabiliza"
                        name="Checkboxes2"
                      />
                      Lançamentos Não Contabilizados
                    </div> -->

                    <div
                      class="text-right col-lg-4"
                      style="margin: 20px; max-width: 140px"
                    >
                      <select
                        class="form-control border-0 form-control-solid font-size-lg font-weight-bolder pl-5 min-h-30px"
                        v-model="anoFluxo"
                      >
                        <option
                          v-for="(item, index) in anos"
                          :key="index"
                          :value="item.ano"
                        >
                          Ano {{ item.ano }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="card-body" id="fluxo">
                    <!--begin::Chart-->
                    <div id="chartFluxo"></div>
                    <apexchart
                      height="350"
                      type="bar"
                      :options="optionsFluxo"
                      :series="seriesFluxo"
                    ></apexchart>
                    <!--end::Chart-->
                  </div>
                </div>
                <!--end::Card-->
              </div>
              <!--end::Advance Table Widget 2-->
            </div>
          </div>
          <!--end::Dashboard-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Entry-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "../../components/layout/SubHeader.vue";
// import SelectPopulado from "../../components/forms/SelectPopulado";
import { mapState, mapActions } from "vuex";

// Shared Colors Definition
const primary = "#6993FF";
const success = "#1BC5BD";
// const info = "#8950FC";
// const warning = "#FFA800";
const danger = "#F64E60";
const mes = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];
export default {
  name: "Home",
  components: {
    SubHeader,
    // SelectPopulado,
  },
  computed: {
    ...mapState("auth", ["empresa"]),
    empresaNome() {
      return this.$store.state.auth.empresa
        ? this.$store.state.auth.empresa.pessoa
          ? this.$store.state.auth.empresa.pessoa.nome
          : ""
        : "";
    },

    valorExtrato() {
      return this.dados.extrato
        .map((x) => x.valor)
        .reduce((acc, value) => acc + value, 0);
    },
    seriesFluxo() {
      return [
        {
          name: "Recebimentos",
          data: this.dados.fluxoAno.map((x) => x.recebido.valor),
          // .filter((f) => f.apoio == "E")
          // .sort((a, b) => a.mes - b.mes)
          // .map((x) => x.valor), // [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Pagamentos",
          data: this.dados.fluxoAno.map((x) => x.pago.valor), //[76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
          name: "Saldo",
          data: this.dados.fluxoAno.map((x) => x.saldo.valor),
          // .filter((f) => f.apoio == "R")
          // .sort((a, b) => a.mes - b.mes)
          // .map((x) => x.valor),
        },
      ];
    },
    optionsFluxo() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: mes,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          title: {
            text: "Valores (R$)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
        colors: [success, danger, primary],
      };
    },

    mesAtual() {
      let data = new Date();
      return mes[data.getMonth()] + "/" + data.getFullYear();
    },
  },
  data: () => ({
    buttons: [
      {
        link: "/cliente/0",
        titulo: "Add Clientes",
      },
      {
        link: "/funcionario/0",
        titulo: "Add Funcionários",
      },
    ],
    dados: {},
    valorMesAtualOrcamento: [],
    seriesOrcamento: null,
    OptionOrcamento: null,
    seriesEvolucao: null,
    optionsEvolucao: null,
    anoFluxo: 0,
    anos: [],
    contabiliza: false,
  }),
  mounted() {
    this.load();
    this.anoFluxo = new Date().getFullYear();
  },
  methods: {
    ...mapActions("dashboard", ["ActionGetDashboard", "ActionGetFluxoAno"]),
    valorMesAtualOrc() {
      let data = new Date();
      let dados = 0;
      if (this.dados.orcamentos.length > 0) {
        dados = this.dados.orcamentos
          .filter((f) => f.mes == data.getMonth() + 1)
          .reduce((acc, value) => acc + Number(value.valor), 0);
      }
      return dados;
    },
    mesesOrcamento() {
      let meses = [];
      this.dados.orcamentos
        .sort((a, b) => a.mes - b.mes)
        .map((x) => x.mes)
        .forEach((element) => {
          meses.push(mes[element - 1]);
        });
      return meses;
    },
    mesesEvolucao() {
      let meses = [];
      this.dados.evolucaoReceber
        .sort((a, b) => a.mes - b.mes)
        .map((x) => x.mes)
        .forEach((element) => {
          meses.push(mes[element - 1]);
        });
      return meses;
    },
    async loadFluxo() {
      try {
        window.KTApp.block("#fluxo", {
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });

        let obj = {
          ano: this.anoFluxo,
          contabiliza: this.contabiliza ? this.contabiliza : undefined,
        };

        let res = await this.ActionGetFluxoAno(obj);
        this.dados.fluxoAno = res;

        let mesMenor = this.dados.fluxoAno
          .sort((a, b) => a.mes - b.mes)
          .map((x) => x.mes)
          .reduce((a, b) => {
            return Math.min(a, b);
          });
        let maior = this.dados.fluxoAno
          .sort((a, b) => a.mes - b.mes)
          .map((x) => x.mes)
          .reduce((a, b) => {
            return Math.max(a, b);
          });
        if (mesMenor > 1) {
          while (mesMenor > 1) {
            mesMenor--;
            this.dados.fluxoAno.unshift({
              mes: mesMenor,
              recebido: { valor: 0 },
              saldo: { valor: 0 },
              pago: { valor: 0 },
            });
          }
        }
        if (maior < 12) {
          while (maior < 11) {
            maior++;
            this.dados.fluxoAno.unshift({
              mes: maior,
              recebido: { valor: 0 },
              saldo: { valor: 0 },
              pago: { valor: 0 },
            });
          }
        }

        this.dados.fluxoAno = this.dados.fluxoAno.sort((a, b) => a.mes - b.mes);
        window.KTApp.unblock("#fluxo");
      } catch (error) {
        window.KTApp.unblock("#fluxo");
      }
    },
    load() {
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando Dashboard...",
      });
      this.ActionGetDashboard(this.$moment(new Date()).format("YYYY-MM-DD"))
        .then((r) => {
          console.log(r);
          this.dados = r;
          this.valorMesAtualOrcamento = this.valorMesAtualOrc();
          this.anos = this.dados.anosFluxo.filter(
            (f) => f.ano != null && f.ano != undefined
          );

          console.log(this.anos);
          this.seriesOrcamento = [
            {
              name: "R$",
              data: this.dados.orcamentos
                .sort((a, b) => a.mes - b.mes)
                .map((x) => Number(x.valor)),
            },
          ];

          if (this.dados.evolucaoReceber.length == 0) {
            for (var i = 1; i < 13; i++) {
              this.dados.evolucaoReceber.push({
                mes: i,
                valor: 0,
              });
            }
          } else {
            let mesMenorEvoc = this.dados.evolucaoReceber
              .sort((a, b) => a.mes - b.mes)
              .map((x) => x.mes)
              .reduce((a, b) => {
                return Math.min(a, b);
              });
            let maiorEvoc = this.dados.evolucaoReceber
              .sort((a, b) => a.mes - b.mes)
              .map((x) => x.mes)
              .reduce((a, b) => {
                return Math.max(a, b);
              });

            if (mesMenorEvoc > 1) {
              while (mesMenorEvoc > 1) {
                mesMenorEvoc--;
                this.dados.evolucaoReceber.unshift({
                  mes: mesMenorEvoc,
                  valor: 0,
                });
              }
            }
            if (maiorEvoc < 12) {
              while (maiorEvoc < 11) {
                maiorEvoc++;
                this.dados.evolucaoReceber.unshift({
                  mes: maiorEvoc,
                  valor: 0,
                });
              }
            }
          }
          this.dados.evolucaoReceber = this.dados.evolucaoReceber.sort(
            (a, b) => a.mes - b.mes
          );

          this.seriesEvolucao = [
            {
              name: "Receber",
              data: this.dados.evolucaoReceber
                .sort((a, b) => a.mes - b.mes)
                .map((x) => Number(x.valor)),
            },
          ];
          var strokeColor = "#D13647";

          let maxValueEvolution = Math.ceil(
            this.dados.evolucaoReceber.length > 0
              ? this.dados.evolucaoReceber
                  .map((x) => x.valor)
                  .reduce((a, b) => {
                    return Math.max(a, b);
                  }) + 100
              : 0
          );

          let minValueEvolution = 0;
          // if (maxValueEvolution > 0) {
          //   minValueEvolution = Math.ceil((maxValueEvolution * 15) / 100);
          // }
          this.optionsEvolucao = {
            chart: {
              type: "area",
              height: 200,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
              sparkline: {
                enabled: true,
              },
              dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 5,
                left: 0,
                blur: 3,
                color: strokeColor,
                opacity: 0.5,
              },
            },
            plotOptions: {},
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              type: "solid",
              opacity: 0,
            },
            stroke: {
              curve: "smooth",
              show: true,
              width: 3,
              colors: [strokeColor],
            },
            xaxis: {
              categories: this.mesesEvolucao(),
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                style: {
                  colors: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-500"
                  ],
                  fontSize: "12px",
                  fontFamily: window.KTApp.getSettings()["font-family"],
                },
              },
              crosshairs: {
                show: false,
                position: "front",
                stroke: {
                  color: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-300"
                  ],
                  width: 1,
                  dashArray: 3,
                },
              },
            },
            yaxis: {
              min: minValueEvolution,
              max: maxValueEvolution,
              labels: {
                show: false,
                style: {
                  colors: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-500"
                  ],
                  fontSize: "12px",
                  fontFamily: window.KTApp.getSettings()["font-family"],
                },
              },
            },
            states: {
              normal: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                  value: 0,
                },
              },
            },
            tooltip: {
              style: {
                fontSize: "12px",
                fontFamily: window.KTApp.getSettings()["font-family"],
              },
              y: {
                formatter: function (val) {
                  return val.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  });
                },
              },
              marker: {
                show: false,
              },
            },
            colors: ["transparent"],
            markers: {
              colors: [
                window.KTApp.getSettings()["colors"]["theme"]["light"][
                  "danger"
                ],
              ],
              strokeColor: [strokeColor],
              strokeWidth: 3,
            },
          };

          console.log(this.optionsEvolucao);

          this.OptionOrcamento = {
            chart: {
              type: "area",
              height: 150,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
              sparkline: {
                enabled: true,
              },
            },
            plotOptions: {},
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              type: "solid",
              opacity: 1,
            },
            stroke: {
              curve: "smooth",
              show: true,
              width: 3,
              colors: [
                window.KTApp.getSettings()["colors"]["theme"]["base"][
                  "success"
                ],
              ],
            },
            xaxis: {
              categories: this.mesesOrcamento(),
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                style: {
                  colors: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-500"
                  ],
                  fontSize: "12px",
                  fontFamily: window.KTApp.getSettings()["font-family"],
                },
              },
              crosshairs: {
                show: false,
                position: "front",
                stroke: {
                  color: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-300"
                  ],
                  width: 1,
                  dashArray: 3,
                },
              },
              tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                  fontSize: "12px",
                  fontFamily: window.KTApp.getSettings()["font-family"],
                },
              },
            },
            yaxis: {
              min: 0,
              max:
                this.dados.orcamentos.length > 0
                  ? this.dados.orcamentos
                      .sort((a, b) => a.mes - b.mes)
                      .map((x) => Number(x.valor))
                      .reduce((a, b) => {
                        return Math.max(a, b);
                      }) + 100
                  : 0,
              labels: {
                show: false,
                style: {
                  colors: window.KTApp.getSettings()["colors"]["gray"][
                    "gray-500"
                  ],
                  fontSize: "12px",
                  fontFamily: window.KTApp.getSettings()["font-family"],
                },
              },
            },
            states: {
              normal: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                  value: 0,
                },
              },
            },
            tooltip: {
              style: {
                fontSize: "12px",
                fontFamily: window.KTApp.getSettings()["font-family"],
              },
              y: {
                formatter: function (val) {
                  return val.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  });
                },
              },
            },
            colors: [
              window.KTApp.getSettings()["colors"]["theme"]["light"]["success"],
            ],
            markers: {
              colors: [
                window.KTApp.getSettings()["colors"]["theme"]["light"][
                  "success"
                ],
              ],
              strokeColor: [
                window.KTApp.getSettings()["colors"]["theme"]["base"][
                  "success"
                ],
              ],
              strokeWidth: 3,
            },
          };

          let mesMenor = this.dados.fluxoAno
            .sort((a, b) => a.mes - b.mes)
            .map((x) => x.mes)
            .reduce((a, b) => {
              return Math.min(a, b);
            });
          let maior = this.dados.fluxoAno
            .sort((a, b) => a.mes - b.mes)
            .map((x) => x.mes)
            .reduce((a, b) => {
              return Math.max(a, b);
            });
          if (mesMenor > 1) {
            while (mesMenor > 1) {
              mesMenor--;
              this.dados.fluxoAno.unshift({
                mes: mesMenor,
                recebido: { valor: 0 },
                saldo: { valor: 0 },
                pago: { valor: 0 },
              });
            }
          }
          if (maior < 12) {
            while (maior < 11) {
              maior++;
              this.dados.fluxoAno.unshift({
                mes: maior,
                recebido: { valor: 0 },
                saldo: { valor: 0 },
                pago: { valor: 0 },
              });
            }
          }

          this.dados.fluxoAno = this.dados.fluxoAno.sort(
            (a, b) => a.mes - b.mes
          );

          window.KTApp.unblockPage();
        })
        .catch(() => {
          window.KTApp.unblockPage();
        });
    },
    changeFluxo(item) {
      this.anoFluxo = item;
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
    anoFluxo(nValue) {
      this.anoFluxo = nValue;
      this.loadFluxo();
    },
    contabiliza(nValue) {
      this.contabiliza = nValue;
      this.loadFluxo();
    },
  },
};
</script>
