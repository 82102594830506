<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :exportacao="exportarLista"
          :canInativar="user.leitura != true"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando funcionário
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px">
                <div class="container">
                  <b>1. Informações Pessoais</b>
                  <hr />
                  <br />

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <b>Empresa:</b>
                          {{ ver.empresa.pessoa.nome }}
                        </td>
                        <td>
                          <b>Tipo:</b>
                          {{
                            ver.pessoa.tipo_pessoa == "J"
                              ? "Pessoa Jurídica"
                              : "Pessoa Física"
                          }}
                        </td>

                        <td>
                          <b>Função:</b>
                          {{ ver.funcao }}
                        </td>
                        <td>
                          <b>Apelido:</b>
                          {{ ver.apelido }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4">
                          <b
                            v-if="
                              this.ver.pessoa.tipo_pessoa == 'Pessoa Física'
                            "
                            >Nome:</b
                          >
                          <b
                            v-if="
                              this.ver.pessoa.tipo_pessoa == 'Pessoa Jurídica'
                            "
                            >Nome Fantasia:</b
                          >
                          {{ ver.pessoa.nome }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    class="table table-bordered"
                    v-if="this.ver.pessoa.tipo_pessoa == 'Pessoa Jurídica'"
                  >
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <b>Razão Social:</b>
                          {{ ver.pessoa.razao_social }}
                        </td>

                        <td colspan="2">
                          <b>CNPJ:</b>
                          {{ ver.pessoa.identificador }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Inscrição Estadual:</b>
                          {{ ver.pessoa.insc_estadual }}
                        </td>

                        <td>
                          <b>Inscrição Municipal:</b>
                          {{ ver.pessoa.insc_municipal }}
                        </td>

                        <td colspan="2">
                          <b>Optante pelo simples:</b>
                          {{ ver.optante_simples ? "Sim" : "Não" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    class="table table-bordered"
                    v-if="this.ver.pessoa.tipo_pessoa == 'Pessoa Física'"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>CPF:</b>
                          {{ ver.pessoa.identificador }}
                        </td>
                        <td>
                          <b>RG:</b>
                          {{ ver.pessoa.rg }}
                        </td>
                        <td>
                          <b>Data de Nascimento:</b>
                          {{ ver.pessoa.data_nascimento }}
                        </td>
                        <td>
                          <b>Sexo:</b>
                          {{ ver.pessoa.sexo }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td
                          colspan="2"
                          v-if="this.ver.pessoa.tipo_pessoa == 'Pessoa Física'"
                        >
                          <b>Carteira de Trabalho:</b>
                          {{ ver.carteira_trabalho }}
                        </td>
                        <td
                          colspan="2"
                          v-if="
                            this.ver.pessoa.tipo_pessoa == 'Pessoa Jurídica'
                          "
                        ></td>
                        <td>
                          <b>Valor do Salário:</b>
                          {{
                            Number(ver.ultimo_salario).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                        </td>
                        <td>
                          <b>Valor Salário Contábil:</b>
                          {{
                            Number(ver.ultimo_salario_contabil).toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <b>Data de Admissão:</b>
                          {{ ver.data_admissao }}
                        </td>
                        <td colspan="2">
                          <b>Data de Demissão:</b>
                          {{ ver.data_demissao }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4">
                          <b>Observação:</b>
                          {{ ver.observacao }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <b>2. Informações de Contato</b>
                  <hr />
                  <br />

                  <table class="table table-bordered">
                    <tbody>
                      <b>Email(s):</b>
                      <tr
                        v-for="item of ver.pessoa.emails"
                        :key="item.id_email"
                      >
                        <td>
                          <b>Nome:</b>
                          {{ item.observacao }}
                        </td>
                        <td>
                          <b>Email:</b>
                          {{ item.email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <b>CEP:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].cep
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Endereço:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].logradouro
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Número:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].numero
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Complemento:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].complemento
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b>Bairro:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].bairro
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Cidade:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].cidade
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Estado:</b>
                          {{
                            ver.pessoa.enderecos.length > 0
                              ? ver.pessoa.enderecos[0].estado
                              : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <b>Telefone(s):</b>
                      <tr
                        v-for="item of ver.pessoa.telefones"
                        :key="item.id_telefone"
                      >
                        <td>
                          <b>Nome:</b>
                          {{ item.observacao }}
                        </td>
                        <td>
                          <b>Telefone:</b>
                          {{ item.telefone }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <b>2. Informações Bancária</b>
                  <hr />
                  <br />

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <b>Código do Banco:</b>
                          {{ ver.conta_bancaria.codigo_banco }}
                        </td>
                        <td colspan="2">
                          <b>Nome do Banco:</b>
                          {{ ver.conta_bancaria.banco }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Agência:</b>
                          {{ ver.conta_bancaria.agencia }}
                        </td>
                        <td>
                          <b>Dígito Agência:</b>
                          {{
                            ver.conta_bancaria
                              ? ver.conta_bancaria.dv_agencia
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Conta:</b>
                          {{ ver.conta_bancaria.conta }}
                        </td>
                        <td>
                          <b>Dígito Conta:</b>
                          {{
                            ver.conta_bancaria
                              ? ver.conta_bancaria.dv_conta
                              : ""
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>tipo conta:</b>
                          {{ ver.conta_bancaria.tipo }}
                        </td>
                        <td colspan="2">
                          <b>Favorecido:</b>
                          {{
                            ver.conta_bancaria
                              ? ver.conta_bancaria.favorecido
                              : ""
                          }}
                        </td>
                        <td>
                          <b>CPF/CNPJ Favorecido:</b>
                          {{
                            ver.conta_bancaria
                              ? ver.conta_bancaria.identificador
                              : ""
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4">
                          <b>Observação:</b>
                          {{ ver.conta_bancaria.observacao }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
import utils from "@/utils";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Funcionario",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_funcionario",
            checked: false,
          },
          {
            header: "Tipo (PJ/PF)",
            field: "pessoa.tipo_pessoa",
            checked: false,
          },
          {
            header: "Apelido",
            field: "apelido",
            checked: false,
          },
          {
            header: "Data de Admissão",
            field: "data_admissao",
            checked: false,
          },
          {
            header: "Data de Demissão",
            field: "data_demissao",
            checked: false,
          },
          {
            header: "Valor do Salário",
            field: "ultimo_salario",
            checked: false,
          },
          {
            header: "Valor Salário Contábil",
            field: "ultimo_salario_contabil",
            checked: false,
          },
          {
            header: "Nome",
            field: "pessoa.nome",
            checked: false,
          },
          /* Juridica */
          {
            header: "Razão Social",
            field: "pessoa.razao_social",
            checked: false,
          },
          {
            header: "CNPJ",
            field: "pessoa.identificador",
            checked: false,
          },
          {
            header: "Optante pelo simples",
            field: "optante_simples",
            checked: false,
          },
          /* Fisica */
          {
            header: "Sexo",
            field: "pessoa.sexo",
            checked: false,
          },
          {
            header: "Data de Nascimento",
            field: "pessoa.data_nascimento",
            checked: false,
          },
          {
            header: "Carteira de Trabalho",
            field: "carteira_trabalho",
            checked: false,
          },
          {
            header: "CPF",
            field: "pessoa.identificador",
            checked: false,
          },
          {
            header: "RG",
            field: "pessoa.rg",
            checked: false,
          },
          /* end-fisica */
          {
            header: "Função",
            field: "funcao",
            checked: false,
          },
          {
            header: "Observação",
            field: "observacao",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "E-mail",
        criaSheet: true,
        dados: "emails",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Email",
            field: "email",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Endereço",
        criaSheet: true,
        dados: "enderecos",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Cep",
            field: "cep",
            checked: false,
          },
          {
            header: "Endereço",
            field: "logradouro",
            checked: false,
          },
          {
            header: "Número",
            field: "numero",
            checked: false,
          },
          {
            header: "Complemento",
            field: "complemento",
            checked: false,
          },
          {
            header: "Bairro",
            field: "bairro",
            checked: false,
          },
          {
            header: "Estado",
            field: "estado",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Telefone",
        criaSheet: true,
        dados: "telefones",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Telefone",
            field: "telefone",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Informações bancárias",
        criaSheet: true,
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Codigo do Banco",
            field: "conta_bancaria.codigo_banco",
            checked: false,
          },
          {
            header: "Nome do Banco",
            field: "conta_bancaria.banco",
            checked: false,
          },
          {
            header: "Tipo de Conta",
            field: "conta_bancaria.tipo",
            checked: false,
          },
          {
            header: "Agência",
            field: "conta_bancaria.agencia",
            checked: false,
          },
          {
            header: "DV Agencia",
            field: "conta_bancaria.dv_agencia",
            checked: false,
          },
          {
            header: "Conta",
            field: "conta_bancaria.conta",
            checked: false,
          },
          {
            header: "Dv Conta",
            field: "conta_bancaria.dv_conta",
            checked: false,
          },
          {
            header: "Favorecido",
            field: "conta_bancaria.favorecido",
            checked: false,
          },
        ],
      },
    ],
    buttons: [
      {
        link: "/funcionario/0",
        titulo: "Add novo funcionário",
      },
    ],
    titulo: "Funcionários",
    colunaPadrao: [
      {
        label: "Empresa",
        field: "empresa.pessoa.nome",
      },
      {
        label: "Nome",
        field: "pessoa.nome",
      },
      {
        label: "Tipo (PJ/PF)",
        field: "pessoa.tipo_pessoa",
        filterOptions: {
          enabled: true,
          filterDropdownItems: ["Pessoa Jurídica", "Pessoa Física"],
          placeholder: "Filtrar",
        },
      },
      /* {
        label: "RG",
        field: "pessoa.rg",
      }, */
      {
        label: "CPF",
        field: "pessoa.identificador",
      },
      /* {
        label: "Email",
        field: "pessoa.primeiroEmail",
      },
      {
        label: "Tel1",
        field: "pessoa.primeiroTelefone",
      },
      {
        label: "Tel2",
        field: "pessoa.segundoTelefone",
      }, */
      {
        label: "Agencia",
        field: "conta_bancaria.agencia",
      },
      {
        label: "Conta",
        field: "conta_bancaria.conta",
      },
      {
        label: "Banco",
        field: "conta_bancaria.banco",
      },
      {
        label: "Favorecido",
        field: "conta_bancaria.favorecido",
      },
      {
        label: "Tipo de Conta",
        field: "conta_bancaria.tipo",
      },
      {
        label: "Função",
        field: "funcao",
      },
      {
        label: "Ação",
        field: "id_funcionario",
      },
    ],
    colunas: [
      {
        nome: "Empresa",
        index: "empresa.pessoa.nome",
        isHtml: false,
      },
      {
        nome: "Tipo (PJ/PF)",
        index: "pessoa.tipo_pessoa",
        isHtml: false,
      },
      {
        nome: "Nome",
        isHtml: false,
        index: "pessoa.nome",
      },
      /* {
        nome: "RG",
        index: "pessoa.rg",
        isHtml: false,
      }, */
      {
        nome: "CPF",
        index: "pessoa.identificador",
        isHtml: false,
      },
      /*       {
        nome: "Email",
        isHtml: false,
        index: "pessoa.primeiroEmail",
      },
      {
        nome: "Tel1",
        isHtml: false,
        index: "pessoa.primeiroTelefone",
      },
      {
        nome: "Tel2",
        isHtml: false,
        index: "pessoa.segundoTelefone",
      }, */
      {
        nome: "Agencia",
        isHtml: false,
        index: "conta_bancaria.agencia",
      },
      {
        nome: "Conta",
        isHtml: false,
        index: "conta_bancaria.conta",
      },
      {
        nome: "Banco",
        isHtml: false,
        index: "conta_bancaria.banco",
      },
      {
        nome: "Favorecido",
        isHtml: false,
        index: "conta_bancaria.favorecido",
      },
      {
        nome: "Tipo de Conta",
        isHtml: false,
        index: "conta_bancaria.tipo",
      },
      {
        nome: "Função",
        isHtml: false,
        index: "funcao",
      },
    ],
    ver: {
      observacao: "",
      data_admissao: null,
      data_demissao: null,
      ultimo_salario: 0,
      ultimo_salario_contabil: 0,
      apelido: "",
      ativo: true,
      funcao: "",
      carteira_trabalho: "",
      dia_pagto: 0,
      id_empresa: 0,
      empresa: {
        pessoa: {
          nome: "",
        },
      },
      pessoa: {
        tipo_pessoa: "J",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
      conta_bancaria: {
        banco: "",
        codigo_banco: "",
        agencia: "",
        dv_agencia: "",
        conta: "",
        dv_conta: "",
        tipo: "",
        ativo: true,
        isPoupanca: false,
      },
    },
    dados: [],
    monta: false,
  }),
  mounted() {
    this.load();
    if (this.user.leitura == true) {
      this.buttons = [];
    }
  },
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  methods: {
    ...mapActions("funcionario", [
      "ActionGetListFuncionarios",
      "ActionInactiveFuncionario",
    ]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListFuncionarios().then((r) => {
        this.dados = r;
        window.KTApp.unblock("#kt_blockui_content");

        this.dados.forEach((el) => {
          if (el.pessoa.tipo_pessoa == "J") {
            el.pessoa.data_nascimento = null;
          } else {
            el.pessoa.data_nascimento = this.$moment(
              el.pessoa.data_nascimento
            ).format("DD/MM/YYYY");
          }
          el.pessoa.identificador =
            el.pessoa.tipo_pessoa == "J"
              ? utils.formatarCnpj(el.pessoa.identificador)
              : utils.formatarCpf(el.pessoa.identificador);
          el.pessoa["tipo_pessoa"] =
            el.pessoa.tipo_pessoa == "J" ? "Pessoa Jurídica" : "Pessoa Física";
          if (el.data_demissao == null) {
            el.data_admissao = this.$moment(el.data_admissao).format(
              "DD/MM/YYYY"
            );
            return el.data_demissao;
          } else {
            el.data_demissao = this.$moment(el.data_demissao).format(
              "DD/MM/YYYY"
            );
          }
          el.data_admissao = this.$moment(el.data_admissao).format(
            "DD/MM/YYYY"
          );

          el.pessoa["primeiroEmail"] =
            el.pessoa.emails.length > 0 ? el.pessoa.emails[0].email : "";
          el.pessoa["primeiroTelefone"] =
            el.pessoa.telefones.length > 0
              ? el.pessoa.telefones[0].telefone
              : "";
          el.pessoa["segundoTelefone"] =
            el.pessoa.telefones.length > 1
              ? el.pessoa.telefones[1].telefone
              : "";

          el["emails"] = el.pessoa.emails;
          el["telefones"] = el.pessoa.telefones;
          el["enderecos"] = el.pessoa.enderecos;
          el.optante_simples = el.optante_simples == true ? "SIM" : "NÃO";
          /*           el.conta_bancaria["agencia"] =
            el.conta_bancaria.agencia.length > 0
              ? el.conta_bancaria.agencia[0].agencia
              : "";
          el.conta_bancaria["conta"] =
            el.conta_bancaria.conta.length > 0
              ? el.conta_bancaria.conta[0].conta
              : "";
          el.conta_bancaria["tipo"] =
            el.conta_bancaria.tipo.length > 0
              ? el.conta_bancaria.tipo[0].tipo
              : ""; */
        });
        this.monta = true;
      });
    },
    visualizar(item) {
      console.log(item);
      this.ver = item;
      this.ver.pessoa.identificador =
        this.ver.pessoa.identificador.length > 11
          ? utils.formatarCnpj(this.ver.pessoa.identificador)
          : utils.formatarCpf(this.ver.pessoa.identificador);
      this.ver.conta_bancaria.identificador =
        this.ver.conta_bancaria.identificador.length > 11
          ? utils.formatarCnpj(this.ver.conta_bancaria.identificador)
          : utils.formatarCpf(this.ver.conta_bancaria.identificador);
    },

    inativar(item) {
      console.log(item);
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente inativar este funcionario?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveFuncionario(item.id_funcionario).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: "funcionario inativada com sucesso",
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      this.$router.push(`/funcionario/${item.id_funcionario}`);
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
  },
};
</script>