<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :exportacao="exportarLista"
          :canInativar="user.leitura != true"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando {{ ver.tipo }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px">
                <div class="container">
                  <div id="imprimir">
                    <b>1. Informações Pessoais</b>
                    <hr />
                    <br />

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="4">
                            <b>Empresa:</b>
                            {{ ver.empresa.pessoa.nome }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <b>Tipo:</b>
                            {{ ver.pessoa.tipo_pessoa }}
                          </td>
                          <td colspan="2">
                            <b>Responsável:</b>
                            {{ ver.responsavel }}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="4">
                            <b
                              v-if="ver.pessoa.tipo_pessoa == 'Pessoa Jurídica'"
                              >Nome Fantasia:</b
                            >
                            <b v-if="ver.pessoa.tipo_pessoa == 'Pessoa Física'"
                              >Nome:</b
                            >
                            {{ ver.pessoa.nome }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      class="table table-bordered"
                      v-if="ver.pessoa.tipo_pessoa == 'Pessoa Física'"
                    >
                      <tbody>
                        <td>
                          <b>Data de Nascimento:</b>
                          {{ ver.pessoa.data_nascimento }}
                        </td>

                        <td>
                          <b>CPF:</b>
                          {{ ver.pessoa.identificador }}
                        </td>

                        <td>
                          <b>RG:</b>
                          {{ ver.pessoa.rg }}
                        </td>
                      </tbody>
                    </table>

                    <table
                      class="table table-bordered"
                      v-if="ver.pessoa.tipo_pessoa == 'Pessoa Jurídica'"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <b>Razão Social:</b>
                            {{ ver.pessoa.razao_social }}
                          </td>

                          <td>
                            <b>CNPJ:</b>
                            {{ ver.pessoa.identificador }}
                          </td>

                          <td>
                            <b>Inscrição Estadual:</b>
                            {{ ver.pessoa.insc_estadual }}
                          </td>

                          <td>
                            <b>Inscrição Municipal:</b>
                            {{ ver.pessoa.insc_municipal }}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="4">
                            <b>Optante pelo simples:</b>
                            {{ ver.optante_simples ? "Sim" : "Não" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="4">
                            <b>Observação:</b>
                            {{ ver.observacao }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <b>2. Informações de Contato</b>
                    <hr />
                    <br />

                    <table class="table table-bordered">
                      <tbody>
                        <b>Email(s):</b>
                        <tr
                          v-for="item of ver.pessoa.emails"
                          :key="item.id_email"
                        >
                          <td>
                            <b>Nome:</b>
                            {{ item.observacao }}
                          </td>
                          <td>
                            <b>Email:</b>
                            {{ item.email }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <b>CEP:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].cep
                                : ""
                            }}
                          </td>
                          <td>
                            <b>Endereço:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].logradouro
                                : ""
                            }}
                          </td>
                          <td>
                            <b>Número:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].numero
                                : ""
                            }}
                          </td>
                          <td>
                            <b>Complemento:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].complemento
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <b>Bairro:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].bairro
                                : ""
                            }}
                          </td>
                          <td>
                            <b>Cidade:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].cidade
                                : ""
                            }}
                          </td>
                          <td>
                            <b>Estado:</b>
                            {{
                              ver.pessoa.enderecos.length > 0
                                ? ver.pessoa.enderecos[0].estado
                                : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered">
                      <tbody>
                        <b>Telefone(s):</b>
                        <tr
                          v-for="item of ver.pessoa.telefones"
                          :key="item.id_telefone"
                        >
                          <td>
                            <b>Nome:</b>
                            {{ item.observacao }}
                          </td>
                          <td>
                            <b>Telefone:</b>
                            {{ item.telefone }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <b>3. Informações Bancária</b>
                    <hr />
                    <br />

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <b>Código do Banco:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.codigo_banco
                                : ""
                            }}
                          </td>
                          <td colspan="2">
                            <b>Nome do Banco:</b>
                            {{
                              ver.contaBancaria ? ver.contaBancaria.banco : ""
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Agência:</b>
                            {{
                              ver.contaBancaria ? ver.contaBancaria.agencia : ""
                            }}
                          </td>
                          <td>
                            <b>Dígito Agência:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.dv_agencia
                                : ""
                            }}
                          </td>

                          <td>
                            <b>Conta:</b>
                            {{
                              ver.contaBancaria ? ver.contaBancaria.conta : ""
                            }}
                          </td>
                          <td>
                            <b>Dígito Conta:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.dv_conta
                                : ""
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="2">
                            <b>Tipo de Conta:</b>
                            {{
                              ver.contaBancaria ? ver.contaBancaria.tipo : ""
                            }}
                          </td>
                          <td>
                            <b>Favorecido:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.favorecido
                                : ""
                            }}
                          </td>
                          <td>
                            <b>CFP/CNPJ Favorecido:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.identificador
                                : ""
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="4">
                            <b>Observação:</b>
                            {{
                              ver.contaBancaria
                                ? ver.contaBancaria.observacao
                                : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                  Fechar
                </button>
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  v-print="'#imprimir'"
                >
                  <i aria-hidden="true" class="fa fa-print"></i>
                  Imprimir
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
import utils from "@/utils";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Cliente",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_cliente",
            checked: false,
          },
          {
            header: "Empresa",
            field: "empresa.pessoa.nome",
            checked: false,
          },
          {
            header: "Tipo",
            field: "tipo",
            checked: false,
          },
          {
            header: "Tipo (PJ/PF)",
            field: "pessoa.tipo_pessoa",
            checked: false,
          },
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
          },
          {
            header: "Razão Social",
            field: "razao_social",
            checked: false,
          },
          {
            header: "Responsável",
            field: "responsavel",
            checked: false,
          },
          {
            header: "Optante pelo Simples",
            field: "optante_simples",
            checked: false,
          },
          {
            header: "Inscrição Estadual",
            field: "pessoa.insc_estadual",
            checked: false,
          },
          {
            header: "Inscrição Municipal",
            field: "pessoa.insc_municipal",
            checked: false,
          },
          {
            header: "Observação",
            field: "observacao",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "E-mail",
        criaSheet: true,
        dados: "emails",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Email",
            field: "email",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Endereço",
        criaSheet: true,
        dados: "enderecos",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Cep",
            field: "cep",
            checked: false,
          },
          {
            header: "Endereço",
            field: "logradouro",
            checked: false,
          },
          {
            header: "Número",
            field: "numero",
            checked: false,
          },
          {
            header: "Complemento",
            field: "complemento",
            checked: false,
          },
          {
            header: "Bairro",
            field: "bairro",
            checked: false,
          },
          {
            header: "Estado",
            field: "estado",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Telefone",
        criaSheet: true,
        dados: "telefones",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Telefone",
            field: "telefone",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Informações bancárias",
        criaSheet: true,
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Codigo do Banco",
            field: "contaBancaria.codigo_banco",
            checked: false,
          },
          {
            header: "Nome do Banco",
            field: "contaBancaria.banco",
            checked: false,
          },
          {
            header: "Tipo de Conta",
            field: "contaBancaria.tipo",
            checked: false,
          },
          {
            header: "Agência",
            field: "contaBancaria.agencia",
            checked: false,
          },
          {
            header: "DV Agencia",
            field: "contaBancaria.dv_agencia",
            checked: false,
          },
          {
            header: "Conta",
            field: "contaBancaria.conta",
            checked: false,
          },
          {
            header: "Dv Conta",
            field: "contaBancaria.dv_conta",
            checked: false,
          },
          {
            header: "Favorecido",
            field: "contaBancaria.favorecido",
            checked: false,
          },
        ],
      },
    ],
    buttons: [
      {
        link: "/cliente/0",
        titulo: "Add novo Cliente / Fornecedor",
      },
    ],
    titulo: "Clientes / Fornecedores",
    colunaPadrao: [
      {
        label: "ID",
        field: "id",
      },
      /* {
        label: "Tipo",
        field: "tipo",
      }, */
      {
        label: "Cliente/Responsável",
        field: "pessoa.nome",
      },
      {
        label: "Tipo (PJ/PF)",
        field: "pessoa.tipo_pessoa",
        filterOptions: {
          enabled: true,
          filterDropdownItems: ["Pessoa Jurídica", "Pessoa Física"],
          placeholder: "Filtrar",
        },
      },
      {
        label: "CNPJ/CPF",
        field: "pessoa.identificador",
      },
      {
        label: "Agencia",
        field: "contaBancaria.agencia",
      },
      {
        label: "Conta",
        field: "contaBancaria.conta",
      },
      {
        label: "DV Conta",
        field: "contaBancaria.dv_conta",
      },
      {
        label: "Banco",
        field: "contaBancaria.banco",
      },
      {
        label: "Favorecido",
        field: "contaBancaria.favorecido",
      },
      // {
      //   label: "Função",
      //   field: "funcao",
      // },
      {
        label: "Tipo de Conta",
        field: "contaBancaria.tipo",
      },
      /* {
        label: "Email",
        field: "pessoa.primeiroEmail",
      },
      {
        label: "Data de Aniversário",
        field: "pessoa.data_nascimento",
      },
      {
        label: "Tel1",
        field: "pessoa.primeiroTelefone",
      },
      {
        label: "Tel2",
        field: "pessoa.segundoTelefone",
      }, */
      {
        label: "Ação",
        field: "id_cliente",
      },
    ],
    colunas: [
      {
        nome: "ID",
        index: "id",
        isHtml: false,
      },
      {
        nome: "Cliente/Responsável",
        html: '<td><b>{0}</b><br><span style="font-size: 10px;">{1}</span>',
        isHtml: true,
        colsFormatar: ["pessoa.nome", "responsavel"],
      },
      /* {
        nome: "Tipo",
        index: "tipo",
        isHtml: false,
      }, */
      {
        nome: "Tipo (PJ/PF)",
        index: "pessoa.tipo_pessoa",
        isHtml: false,
      },
      {
        nome: "CNPJ/CPF",
        index: "pessoa.identificador",
        isHtml: false,
      },
      {
        nome: "Agencia",
        isHtml: false,
        index: "contaBancaria.agencia",
      },
      {
        nome: "Conta",
        isHtml: false,
        index: "contaBancaria.conta",
      },
      {
        nome: "DV Conta",
        isHtml: false,
        index: "contaBancaria.dv_conta",
      },
      {
        nome: "Banco",
        isHtml: false,
        index: "contaBancaria.banco",
      },
      {
        nome: "Favorecido",
        isHtml: false,
        index: "contaBancaria.favorecido",
      },
      // {
      //   nome: "Função",
      //   isHtml: false,
      //   index: "funcao",
      // },
      {
        nome: "Tipo de Conta",
        isHtml: false,
        index: "contaBancaria.tipo",
      },
      /* {
        nome: "Email",
        index: "pessoa.primeiroEmail",
        isHtml: false,
      },
      {
        nome: "Data de Aniversário",
        isHtml: false,
        index: "pessoa.data_nascimento",
      },
      {
        nome: "Tel1",
        isHtml: false,
        index: "pessoa.primeiroTelefone",
      },
      {
        nome: "Tel2",
        isHtml: false,
        index: "pessoa.segundoTelefone",
      }, */
    ],
    dados: [],
    monta: false,
    ver: {
      optante_simples: 0,
      id_empresa: 0,
      empresa: {
        pessoa: {
          nome: "",
        },
      },
      pessoa: {
        tipo_pessoa: "",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
      contaBancaria: {
        banco: "",
        codigo_banco: "",
        agencia: "",
        dv_agencia: "",
        conta: "",
        dv_conta: "",
        tipo: "",
        ativo: true,
        isPoupanca: false,
      },
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        ativo: true,
      },
    },
  }),
  mounted() {
    this.load();
    if (this.user.leitura == true) {
      this.buttons = [];
    }
    // .catch((error) => {
    //  if (error.status != 401) {
    //     if (error.data) {
    //       console.log(error.data);
    //       this.$swal.fire({
    //         icon: "error",
    //         title: error.data.error.title || "Oops...",
    //         text: error.data.error.message,
    //       });
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Ocorreu um erro inesperado, favor contactar o suporte.",
    //       });
    //     }
    //   }
    // });
  },
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  methods: {
    ...mapActions("cliente", ["ActionGetListCliente", "ActionInactiveCliente"]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListCliente().then((r) => {
        this.dados = r;
        console.log(this.dados);
        window.KTApp.unblock("#kt_blockui_content");
        this.dados.forEach((el) => {
          el["id"] = el.id_cliente || el.id_fornecedor;
          el["tipo"] = (el.id_cliente
            ? "cliente"
            : el.id_fornecedor
            ? "fornecedor"
            : ""
          ).toUpperCase();
          el.pessoa.identificador =
            el.pessoa.tipo_pessoa == "J"
              ? utils.formatarCnpj(el.pessoa.identificador)
              : utils.formatarCpf(el.pessoa.identificador);
          el.pessoa["tipo_pessoa"] =
            el.pessoa.tipo_pessoa == "J" ? "Pessoa Jurídica" : "Pessoa Física";
          el.responsavel = el.responsavel ? el.responsavel : "";

          el.pessoa["primeiroEmail"] =
            el.pessoa.emails.length > 0 ? el.pessoa.emails[0].email : "";
          el.pessoa["primeiroTelefone"] =
            el.pessoa.telefones.length > 0
              ? el.pessoa.telefones[0].telefone
              : "";
          el.pessoa["segundoTelefone"] =
            el.pessoa.telefones.length > 1
              ? el.pessoa.telefones[1].telefone
              : "";
          el["emails"] = el.pessoa.emails;
          el["telefones"] = el.pessoa.telefones;
          el["enderecos"] = el.pessoa.enderecos;
          el.optante_simples = el.optante_simples == true ? "SIM" : "NÃO";
          /* el.contaBancaria["agencia"] =
            el.contaBancaria.agencia > 0
              ? el.contaBancaria.agencia[0].agencia
              : "";
          el.contaBancaria["conta"] =
            el.contaBancaria.conta.length > 0
              ? el.contaBancaria.conta[0].conta
              : "";
          el.contaBancaria["tipo"] =
            el.contaBancaria.tipo.length > 0
              ? el.contaBancaria.tipo[0].tipo
              : ""; */
          if (el.pessoa.tipo_pessoa == "Pessoa Jurídica") {
            el.pessoa.data_nascimento = null;
          } else {
            el.pessoa.data_nascimento = this.$moment(
              el.pessoa.data_nascimento
            ).format("DD/MM/YYYY");
          }

          /* el.pessoa.data_nascimento = el.pessoa.data_nascimento
            ? this.$moment(el.pessoa.data_nascimento).format("DD/MM/YYYY")
            : ""; */
        });

        this.monta = true;
      });
    },
    visualizar(item) {
      this.ver = item;

      this.ver.pessoa.identificador =
        this.ver.pessoa.identificador.length > 11
          ? utils.formatarCnpj(this.ver.pessoa.identificador)
          : utils.formatarCpf(this.ver.pessoa.identificador);
      this.ver.contaBancaria.identificador =
        this.ver.contaBancaria.identificador.length > 11
          ? utils.formatarCnpj(this.ver.contaBancaria.identificador)
          : utils.formatarCpf(this.ver.contaBancaria.identificador);
      console.log(item);
      console.log(this.ver);
    },
    inativar(item) {
      console.log(item);
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: `Deseja realmente inativar este ${item.tipo}?`,
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveCliente({
              id: item.id,
              tipo: item.tipo.toLowerCase(),
            }).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: `${item.tipo} inativado com sucesso`,
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      if (item.tipo == "CLIENTE") {
        this.$router.push(`/cliente/${item.id_cliente}/cliente`);
      } else {
        this.$router.push(`/cliente/${item.id_fornecedor}/fornecedor`);
      }
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
  },
};
</script>