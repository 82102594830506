import { http } from "@/http";

export const ActionGetListMovimento = (context, payload) => {

    let url = `movimentacao?id_empresa=${payload.id}`;
    if (payload.isPagar) {
        url += `&isPagar=true`;
    }
    if (payload.isReceber) {
        url += `&isReceber=true`;
    }

    return http.get(url).then(r => r.data);

};
export const ActionUpdateMovimento = (context, payload) => {
    let url = `movimentacao`;
    return http.put(url, payload).then(r => r.data);
};

export const ActionDeleteMovimento = (context, payload) => {
    let url = `movimentacao/${payload}`;
    return http.delete(url).then(r => r.data);
};


export const ActionGetMovimentacao = (context, payload) => {
    let url = `movimentacao/get/${payload}`;
    return http.get(url).then(r => r.data);
};


export const ActionEfetivarMovimentacao = (context, payload) => {
    let url = `movimentacao/efetivar`;
    return http.put(url, payload).then(r => r.data);
};

export const ActionEfetivarMovimentacaoMuitos = (context, payload) => {
    let url = `movimentacao/efetivar-muitos`;
    return http.put(url, { lancamentos: payload }).then(r => r.data);
};