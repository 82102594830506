<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="[]"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="card card-custom">
          <div
            class="alert alert-custom alert-white alert-shadow fade show gutter-b"
            role="alert"
          >
            <div class="alert-icon">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <div class="alert-text">
              <div
                class="input-daterange input-group col-lg-9"
                id="kt_datepicker_5"
              >
                <input
                  type="month"
                  v-model="dataInicio"
                  class="form-control"
                  name="start"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="la la-ellipsis-h"></i>
                  </span>
                </div>
                <input
                  type="month"
                  v-model="dataFim"
                  class="form-control"
                  name="end"
                />

                <div
                  class="checkbox-lg"
                  style="margin-top: 10px; margin-left: 20px; font-size: 13px"
                >
                  <input type="checkbox" v-model="futuro" name="Checkboxes2" />
                  Laçamentos Futuros
                </div>

                <!-- <div
                  class="checkbox-lg"
                  style="margin-top: 10px; margin-left: 20px; font-size: 13px"
                >
                  <input
                    type="checkbox"
                    v-model="contabiliza"
                    name="Checkboxes2"
                  />
                  Laçamentos Não Contabilizados
                </div> -->
              </div>
            </div>
          </div>

          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <img src="assets/media/svg/icons/Shopping/Chart-bar1.svg" />
                Pagamentos {{ mesInicio }} à {{ mesFim }}
              </h3>
            </div>
          </div>
          <div class="card-body" style="margin-left: 300px">
            <!--begin::Chart-->

            <apexchart
              type="donut"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
          <!--end::Chart-->

          <hr />
          <div class="card-body">
            <!--begin: Datatable-->
            <div style="text-align: right">
              <xlsx-workbook>
                <xlsx-sheet
                  :collection="sheet.data"
                  v-for="sheet in sheets"
                  :key="sheet.name"
                  :sheet-name="sheet.name"
                />
                <xlsx-download :filename="nomeArq">
                  <button type="button" class="btn btn-outline-info btn-sm">
                    <i class="fa fa-cog icon-lg"></i>
                    EXPORTAR
                  </button>
                </xlsx-download>
              </xlsx-workbook>
            </div>
            <table
              class="table table-bordered table-hover table-checkable"
              id="kt_datatable"
              style="margin-top: 13px !important"
            >
              <thead>
                <tr>
                  <th>Categoria</th>
                  <th>Percentual</th>
                  <th>Valor(R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dados" :key="index">
                  <td>{{ item.categoria }}</td>
                  <td>
                    <b style="color: blue">{{ Number(item.percentual) }}%</b>
                    <br />
                  </td>
                  <td>
                    <b style="color: green">
                      {{
                        Number(item.valor).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                    <br />
                  </td>
                </tr>
                <tr style="background-color: rgba(190, 190, 190, 0.2)">
                  <td colspan="2">
                    <b>TOTAL PAGO</b>
                  </td>
                  <td>
                    <b style="color: blue">
                      {{
                        total.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--end: Datatable-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
// Shared Colors Definition
const primary = "#6993FF";
const success = "#1BC5BD";
const info = "#8950FC";
const warning = "#FFA800";
const danger = "#F64E60";
const mes = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];
import SubHeader from "../../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
import {
  XlsxDownload,
  XlsxWorkbook,
  XlsxSheet,
} from "vue-xlsx/dist/vue-xlsx.es.js";

export default {
  components: {
    SubHeader,
    XlsxDownload,
    XlsxWorkbook,
    XlsxSheet,
  },
  data: () => ({
    titulo: "Análise de Pagamentos",
    dataInicio: null,
    dataFim: null,
    dados: [],
    options: {},
    series: [],
    futuro: false,
    contabiliza: false,
  }),
  computed: {
    ...mapState("auth", ["empresa"]),
    nomeArq() {
      return `${process.env.VUE_APP_NOME}_Análise de Pagamentos_${
        this.dataInicio
      }_${this.dataFim}_${this.$moment(new Date()).format(
        "DD_MM_YYYY_HHmmss"
      )}.xlsx`;
    },
    total() {
      return this.dados.length == 0
        ? 0
        : this.dados.reduce((acc, value) => {
            return acc + Number(value.valor);
          }, 0);
    },
    sheets() {
      let arrays = [];
      let obj = { name: "Analise de Pagamentos", data: [] };
      obj.data = this.dados.map((x) => {
        return {
          Categoria: x.categoria,
          Percentual: x.percentual,
          Valor: x.valor,
        };
      });
      arrays.push(obj);
      return arrays;
    },
    mesInicio() {
      let data = this.$moment(`${this.dataInicio}-01`)._d;
      return `${mes[data.getMonth()]}/${data.getFullYear()}`;
    },
    mesFim() {
      let data = this.$moment(`${this.dataFim}-01`)._d;
      return `${mes[data.getMonth()]}/${data.getFullYear()}`;
    },
  },
  mounted() {
    let data = new Date();
    data = new Date(data.getFullYear(), data.getMonth(), 1);
    let data2 = new Date(data.getFullYear(), data.getMonth() + 1, 1);
    data2 = data2.setDate(data2.getDate() - 1);
    this.dataInicio = this.$moment(data).format("YYYY-MM");
    this.dataFim = this.$moment(data2).format("YYYY-MM");
    this.load();
  },
  methods: {
    ...mapActions("relatorio", ["ActionGetAcompanhamentoPagar"]),
    seriesFluxo(dados) {
      return dados.map((x) => x.valor);
    },
    optionsFluxo(categorias) {
      return {
        chart: {
          width: 500,
          type: "donut",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
        labels: categorias,
        responsive: [
          {
            breakpoint: 100,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: [primary, success, warning, danger, info],
      };
    },

    load() {
      window.KTApp.blockPage("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });

      let obj = {
        mesInicio: this.dataInicio,
        mesFim: this.dataFim,
        efetivado: !this.futuro,
        contabiliza: this.contabiliza ? this.contabiliza : undefined,
      };

      this.ActionGetAcompanhamentoPagar(obj).then((r) => {
        this.dados = r;
        this.options = this.optionsFluxo(this.dados.map((x) => x.categoria));
        this.series = this.seriesFluxo(this.dados);
        window.KTApp.unblockPage();
        if (this.dados.length == 0) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Não existe dados para o relatório",
          });
          return;
        }
      });
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      this.load();
    },
    dataInicio(nValue) {
      this.dataInicio = nValue;
      this.load();
    },
    futuro(nValue) {
      this.futuro = nValue;
      this.load();
    },
    contabiliza(nValue) {
      this.contabiliza = nValue;
      this.load();
    },
  },
};
</script>