<template>
  <div
    class="content d-flex flex-column flex-column-fluid"
    id="kt_content"
    v-disable-all="user.leitura == true"
  >
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
      <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    </div>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text">
            <div
              class="input-daterange input-group col-lg-7"
              id="kt_datepicker_5"
            >
              <input
                type="date"
                v-model="dataInicio"
                class="form-control"
                name="start"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input
                type="date"
                v-model="dataFim"
                class="form-control"
                name="end"
              />
            </div>
          </div>

          <div style="margin: 25px; margin-right: 50px">
            Conciliação Pendente:
            <b style="color: red">({{ AConciliar }})</b>
          </div>

          <div style="width: 200px">
            <VueFileAgent
              ref="vueFileAgent"
              :theme="'list'"
              :accept="'.ofx'"
              :multiple="false"
              :deletable="true"
              :meta="true"
              :maxSize="'10MB'"
              :maxFiles="1"
              v-model="fileRecords"
              @select="filesSelected($event)"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
              :helpText="'Importar'"
            ></VueFileAgent>
          </div>
        </div>
        <div class="row" id="kt_blockui_content">
          <Card
            :tamanho="4"
            :titulo="'Banco'"
            :isImport="true"
            :dados="banco"
            :dadosSistema="sistema"
            :dadosSelect="sistemaLabel"
            @onDadosPopulado="populaDadosBanco"
            @onConciliarPontual="conciliaPontual"
            @onConciliarAll="conciliaAll"
            @onNovo="efetuouNovo"
            @onCheckAll="checkAll"
          />
          <!-- <Card
            :tamanho="8"
            :titulo="'Sistema'"
            :isDate="true"
            :dados="sistema"
            :dataInicio="dataInicio"
            :dataFim="dataFim"
          />-->
        </div>
      </div>

      <!-- Button trigger modal-->

      <!--end::Row-->
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</template>

<script>
import SubHeader from "../../components/layout/SubHeader";
// import Listagem from "../../components/forms/Listagem.vue";
import Card from "./components/Card";
import { mapState, mapActions } from "vuex";
import { parse as parseOFX } from "ofx-js";
import Vue from "vue";
export default {
  data: () => ({
    dadosConciliados: [],
    dadosSistema: [],
    dadosBanco: [],
    fileRecords: [],
    fileRecordsForUpload: [],
    titulo: "Conciliação",
    buttons: [
      {
        link: "/extrato",
        titulo: "Voltar ao extrato",
      },
    ],
    dataInicio: null,
    dataFim: null,
  }),
  computed: {
    ...mapState("auth", ["empresa","user"]),
    sistema() {
      return this.dadosSistema;
    },
    AConciliar() {
      return this.dadosBanco.filter((f) => f.id_movimentacao == null).length;
    },
    sistemaLabel() {
      return this.dadosSistema.map((x) => {
        return {
          label: `${x.id_movimentacao} - ${x.nome} - ${
            x.data_lancamento
          } : ${Number(x.valor).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })} `,
          value: x.id_movimentacao,
        };
      });
    },
    banco() {
      return this.dadosBanco;
    },
  },
  mounted() {
    let data = new Date();
    data = data.setDate(1);
    this.dataInicio = this.$moment(data).format("YYYY-MM-DD");
    this.dataFim = this.$moment(new Date().setDate(+31)).format("YYYY-MM-DD");
  },
  methods: {
    ...mapActions("extrato", [
      "ActionGetConciliacao",
      "ActionPostValidaCodigo",
    ]),
    ...mapActions("movimentacao", [
      "ActionEfetivarMovimentacao",
      "ActionEfetivarMovimentacaoMuitos",
    ]),
    checkAll(item) {
      this.dadosBanco.forEach((x, index) => {
        if (x.id_movimentacao != undefined) {
          x.checked = item;
          Vue.set(this.dadosBanco, index, x);
        }
      });

      console.log(this.dadosBanco);
      console.log(
        this.dadosBanco.filter((el) => el.id_movimentacao != undefined)
      );
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        // const data = ofx.parse(e.target.result);
        parseOFX(e.target.result).then((ofx) => {
          let dada =
            ofx.OFX["BANKMSGSRSV1"]["STMTTRNRS"]["STMTRS"]["BANKTRANLIST"][
              "STMTTRN"
            ];

          dada = dada.map((x, index) => {
            return {
              data_lancamento: this.$moment(
                x["DTPOSTED"].substring(0, 8)
              ).format("DD/MM/YYYY"),
              nome: x["MEMO"],
              valor: Number(x["TRNAMT"]),
              id_movimentacao: null,
              codigo: x["FITID"],
              tipo: Number(x["TRNAMT"]) > 0 ? "R" : "P",
              todos: false,
              index: index,
              data_lancamento_apoio: this.$moment(
                x["DTPOSTED"].substring(0, 8)
              ).format("YYYY-MM-DD"),
              juros: 0,
              descontos: 0,
            };
          });

          // fazer a validação dos Códigos
          this.ActionPostValidaCodigo(
            dada.map((x) => {
              return {
                data_lancamento: x.data_lancamento_apoio,
                codigo: x.codigo,
                valor: x.valor,
              };
            })
          ).then((r) => {
            if (r.codigos.filter((f) => f.existe == true).length > 0) {
              let exist = r.codigos.filter((f) => f.existe == true);

              dada = dada.filter(
                (f) =>
                  exist.filter(
                    (x) =>
                      x.codigo == f.codigo &&
                      x.data_lancamento == f.data_lancamento_apoio &&
                      x.valor == f.valor
                  ).length == 0
              );
            }
            this.dadosBanco = dada;

            if (this.dadosBanco.length == 0) {
              this.$swal.fire({
                icon: "warning",
                title: "Atenção",
                text: "Todas os lançamentos inserido já foram concilados.",
              });
            }
            this.fileRecordsForUpload = [];
            this.fileRecords = [];
            window.KTApp.unblock("#kt_blockui_content");
          });
        });
      };

      reader.readAsText(this.fileRecordsForUpload[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$swal
          .fire({
            icon: "question",
            title: "Aviso",
            text: "Deseja realmente excluir este anexo?",
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
          })
          .then((r) => {
            if (r.value) {
              this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
            }
          });
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    efetuouNovo(item) {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Conciliando...",
      });
      this.dadosBanco = this.dadosBanco.filter((f) => f.index != item.index);
      this.load();
      window.KTApp.unblock("#kt_blockui_content");
    },
    conciliaPontual(item) {
      console.log(item);

      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Conciliando...",
      });
      this.ActionEfetivarMovimentacao({
        id_movimentacao: item.id_movimentacao,
        data_efetivacao: this.$moment(new Date()).format("YYYY-MM-DD"),
        juros: item.juros,
        descontos: item.descontos,
        codigo_efetivacao: item.codigo,
        efetivado: true,
      }).then((x) => {
        if (x.sucesso == true) {
          this.dadosBanco = this.dadosBanco.filter(
            (f) => f.index != item.index
          );
          this.load();
          window.KTApp.unblock("#kt_blockui_content");
        }
      });
    },
    conciliaAll(items) {
      console.log(items);

      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Conciliando...",
      });
      let dados = items.map((x) => {
        return {
          id_movimentacao: x.id_movimentacao,
          data_efetivacao: this.$moment(new Date()).format("YYYY-MM-DD"),
          juros: x.juros,
          descontos: x.descontos,
          codigo_efetivacao: x.codigo,
          efetivado: true,
        };
      });

      this.ActionEfetivarMovimentacaoMuitos(dados).then((x) => {
        if (x.sucesso == true) {
          this.dadosBanco = this.dadosBanco.filter(
            (f) => items.filter((x) => x.index == f.index).length == 0
          );
          this.load();
          window.KTApp.unblock("#kt_blockui_content");
        }
      });
    },
    load() {
      if (this.dataInicio == null || this.dataFim == null) {
        return;
      }
      this.ActionGetConciliacao({
        idEmpresa: this.empresa.id_empresa,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
      }).then((r) => {
        this.dadosSistema = r;
        // this.$emit("onDadosPopulado", this.dados);
      });
    },
    populaDadosBanco(item) {
      this.dadosBanco = item;
    },
    populaDadosSistema(item) {
      this.dadosSistema = item;
    },
    acaoDadosImportados(item) {
      // aplicar leitura do OFX
      this.dadosBanco = item;
      if (this.dadosSistema.length > 0) {
        this.verificarDados();
      }
    },
    verificarDados() {
      this.dadosBanco.map((x) => {
        if (
          this.dadosSistema.filter(
            (y) => y.data_lancamento == x.data_lancamento && y.valor == x.valor
          ).length == 1
        ) {
          x.id_movimentacao = this.dadosSistema.filter(
            (y) => y.data_lancamento == x.data_lancamento && y.valor == x.valor
          )[0].id_movimentacao;
        }
      });
    },
  },
  /*   computed: {
    ...mapState("auth", ["empresa"]),
  },
  mounted() {
    this.loadConciliados();
  },
  methods: {
    ...mapActions("extrato", ["ActionGetExtrato"]),
    loadConciliados() {
      this.ActionGetExtrato().then((r) => {
        this.dadosConciliados = r;
        this.monta = true;
      });
    },
  }, */
  components: { SubHeader, Card }, // Listagem,
  watch: {
    dataInicio(nValue) {
      this.dataInicio = nValue;
      this.load();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      this.load();
    },
    empresa(nValue) {
      this.empresa = nValue;
      this.dataInicio = null;
      this.dataFim = null;
      this.dados = [];
      this.load();
    },
    dadosSistema(nValue) {
      this.dadosSistema = nValue;
      if (this.dadosBanco.length > 0) {
        this.verificarDados();
      }
    },
    dadosBanco(nValue) {
      this.dadosBanco = nValue;
      if (this.dadosSistema.length > 0) {
        this.verificarDados();
      }
    },
  },
};
</script>