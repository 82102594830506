<template>
  <Conta
    v-disable-all="user.leitura == true"
    :data="contaReceber"
    :tipo="tipo"
    :titulo="titulo"
    :buttons="buttons"
    @onCancel="voltar"
    @onSave="manter"
    :opcao="op"
    :conciliado="conc"
  />
</template>
<script>
import Conta from "../Components/conta";
import { mapActions, mapState } from "vuex";
export default {
  name: "ContasReceber",
  props: {
    conciliado: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    conta: {
      id_categoria: undefined,
      id_obj: undefined,
      id_periodicidade: undefined,
      recorrecia: undefined,
      data_inclusao: "",
      data_fim: "",
      nome: "",
      descricao: "",
      anexo: "",
      valor: 0,
    },
    tipo: "Recebimento",
    opcao: "A",
    buttons: [
      {
        link: "/conta-receber",
        titulo: "ver todas Contas",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    op() {
      return this.opcao;
    },
    conc() {
      return this.conciliado;
    },
    contaReceber: {
      get() {
        return this.conta;
      },
      set(nValue) {
        this.conta = nValue;
      },
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Conta à Receber";
      } else {
        return "Editando Conta à Receber";
      }
    },
  },
  mounted() {
    this.opcao = "A";

    if (this.$route.params.id != 0 && this.$route.params.id != undefined) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetMovimentacao(this.$route.params.id).then((r) => {
        // let conta = r;
        this.contaReceber = {
          id_servico: r.receber.servico
            ? r.receber.servico.id_servico
            : undefined,
          id_categoria: r.receber.categoria
            ? r.receber.categoria.id_categoria
            : undefined,
          id_periodicidade: r.receber.periodicidade
            ? r.receber.periodicidade.id_periodicidade
            : null,
          ocorrencia: r.receber.ocorrencia,
          id_funcionario: r.receber.id_funcionario,
          data_inclusao: this.$moment(r.data_inclusao).format("YYYY-MM-DD"),
          data_lancamento: r.data_lancamento
            ? this.$moment(r.data_lancamento).format("YYYY-MM-DD")
            : undefined,
          nome: r.receber.nome,
          descricao: r.descricao,
          anexo: null,
          valor: r.valor,
          id_conta_receber: r.id_conta_receber,
          id_conta: r.id_conta_receber,
          id_movimentacao: r.id_movimentacao,
          receber: r.receber,
          recebimento:
            r.efetivado == true
              ? {
                  valor: r.valor,
                  juros: r.juros,
                  descontos: r.descontos,
                  data_efetivacao: r.data_efetivacao,
                  codigo_efetivacao: r.codigo_efetivacao,
                }
              : undefined,
        };
        if (r.receber.id_funcionario == undefined) {
          this.contaReceber["id_obj"] = r.receber.id_cliente
            ? "C" + r.receber.id_cliente
            : "F" + r.receber.id_fornecedor;
        }
        window.KTApp.unblockPage();
      });
    }
  },
  methods: {
    ...mapActions("contaReceber", [
      "ActionCreateContaReceber",
      "ActionUpdateContaReceber",
      "ActionCreateConciliadoContaReceber",
    ]),
    ...mapActions("movimentacao", [
      "ActionGetMovimentacao",
      "ActionUpdateMovimento",
      "ActionEfetivarMovimentacao",
    ]),
    ...mapActions("anexo", ["ActionUploadAnexo", "ActionCriaAnexo"]),
    manter(item) {
      this.contaReceber = item;
      this.contaReceber[
        "id_empresa"
      ] = this.$store.state.auth.empresa.id_empresa;

      if (
        this.contaReceber.data_lancamento == undefined ||
        this.contaReceber.data_lancamento == null
      ) {
        this.contaReceber.data_lancamento = this.contaReceber.data_inclusao;
      }

      if (this.opcao == "A") {
        this.contaReceber.data_inicio = this.contaReceber.data_lancamento;
        this.contaReceber.ocorrencia = this.contaReceber.ocorrencia
          ? this.contaReceber.ocorrencia
          : 1;

        let resp = null;

        if (this.contaReceber.recebimento != undefined) {
          resp = this.ActionCreateConciliadoContaReceber(this.contaReceber);
        } else {
          resp = this.ActionCreateContaReceber(this.contaReceber);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        resp
          .then((x) => {
            window.KTApp.unblockPage();
            if (x.sucesso == true) {
              if (this.contaReceber.anexo) {
                this.ActionCriaAnexo({
                  id_conta_receber: x.contasReceber.id_conta,
                  nome: `${this.contaReceber.nome} - ${this.$moment(
                    this.contaReceber.data_lancamento
                  ).format("DD/MM/YYYY")}`,
                  descricao: this.contaReceber.descricao,
                }).then((r) => {
                  if (r.sucesso == true) {
                    let obj = {
                      id: r.anexo.id_anexo,
                      anexo: this.contaReceber.anexo,
                      op: "contas-pagar",
                      conta: x.contasReceber.id_conta,
                      tipo: "R",
                    };
                    this.ActionUploadAnexo(obj).then((xx) => {
                      this.$swal
                        .fire({
                          icon: xx.sucesso == true ? "success" : "error",
                          title:
                            xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                          text:
                            xx.sucesso == true
                              ? this.opcao == "A"
                                ? `Conta adicionada com sucesso`
                                : "Conta Alterada com Sucesso"
                              : xx.error.message,
                        })
                        .then(() => {
                          if (this.conc != undefined) {
                            this.$emit("onNovo", true);
                          } else {
                            this.$router.push("/conta-receber");
                          }
                        });
                    });
                  }
                });
              } else {
                this.$swal
                  .fire({
                    icon: x.sucesso == true ? "success" : "error",
                    title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                    text:
                      x.sucesso == true
                        ? this.opcao == "A"
                          ? `Conta adicionada com sucesso`
                          : "Conta Alterada com Sucesso"
                        : x.error.message,
                  })
                  .then(() => {
                    if (this.conc != undefined) {
                      this.$emit("onNovo", true);
                    } else {
                      this.$router.push("/conta-receber");
                    }
                  });
              }
            }
          })
          .catch(() => {
            window.KTApp.unblockPage();
          });
      } else if (
        this.contaReceber.recebimento == undefined &&
        this.contaReceber.ocorrencia > 1 &&
        (this.contaReceber.receber.data_fim == null ||
          new Date(this.contaReceber.data_lancamento) <
            new Date(this.contaReceber.receber.data_fim))
      ) {
        this.$swal
          .fire({
            title: "Atenção",
            icon: "question",
            html: `Você está prestes a alterar um lançamento recorrente. Deseja alterar todos os lançamentos? `,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
          .then((x) => {
            let resp;
            if (x) {
              resp = this.ActionUpdateContaReceber(this.contaReceber);
            } else {
              resp = this.ActionUpdateMovimento(this.contaReceber);
            }
            resp.then((x) => {
              if (this.contaReceber.anexo) {
                this.ActionCriaAnexo({
                  id_conta_receber: this.contaReceber.id_conta_receber,
                  id_movimentacao: this.contaReceber.id_movimentacao,
                  nome: `${this.contaReceber.nome} - ${this.$moment(
                    this.contaReceber.data_lancamento
                  ).format("DD/MM/YYYY")}`,
                  descricao: this.contaReceber.descricao,
                }).then((r) => {
                  if (r.sucesso == true) {
                    let obj = {
                      id: r.anexo.id_anexo,
                      anexo: this.contaReceber.anexo,
                      op: "contas-receber",
                    };
                    this.ActionUploadAnexo(obj).then((x) => {
                      this.$swal
                        .fire({
                          icon: x.sucesso == true ? "success" : "error",
                          title:
                            x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                          text:
                            x.sucesso == true
                              ? this.opcao == "A"
                                ? `Conta adicionada com sucesso`
                                : "Conta Alterada com Sucesso"
                              : x.error.message,
                        })
                        .then(() => {
                          this.$router.push("/conta-receber");
                        });
                    });
                  }
                });
              } else {
                if (x.sucesso == true) {
                  this.$swal
                    .fire({
                      icon: x.sucesso == true ? "success" : "error",
                      title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                      text:
                        x.sucesso == true
                          ? this.opcao == "A"
                            ? `Conta adicionada com sucesso`
                            : "Conta Alterada com Sucesso"
                          : x.error.message,
                    })
                    .then(() => {
                      this.$router.push("/conta-receber");
                    });
                }
              }
            });
          })
          .catch(() => {
            window.KTApp.unblockPage();
          });
      } else {
        if (this.contaReceber.recebimento != undefined) {
          this.contaReceber.recebimento[
            "id_movimentacao"
          ] = this.contaReceber.id_movimentacao;
          this.ActionEfetivarMovimentacao(this.contaReceber.recebimento)
            .then((x) => {
              if (this.contaReceber.anexo) {
                this.ActionCriaAnexo({
                  id_conta_receber: this.contaReceber.id_conta_receber,
                  id_movimentacao: this.contaReceber.id_movimentacao,
                  nome: `${this.contaReceber.nome} - ${this.$moment(
                    this.contaReceber.data_lancamento
                  ).format("DD/MM/YYYY")}`,
                  descricao: this.contaReceber.descricao,
                }).then((r) => {
                  if (r.sucesso == true) {
                    let obj = {
                      id: r.anexo.id_anexo,
                      anexo: this.contaReceber.anexo,
                      op: "contas-receber",
                    };
                    this.ActionUploadAnexo(obj).then((xx) => {
                      this.$swal
                        .fire({
                          icon: xx.sucesso == true ? "success" : "error",
                          title:
                            xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                          text:
                            xx.sucesso == true
                              ? `Conta efetivada com sucesso`
                              : xx.error.message,
                        })
                        .then(() => {
                          this.$router.push("/conta-receber");
                        });
                    });
                  }
                });
              } else {
                if (x.sucesso == true) {
                  this.$swal
                    .fire({
                      icon: x.sucesso == true ? "success" : "error",
                      title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                      text:
                        x.sucesso == true
                          ? `Conta efetivada com sucesso`
                          : x.error.message,
                    })
                    .then(() => {
                      this.$router.push("/conta-receber");
                    });
                }
              }
            })
            .catch(() => {
              window.KTApp.unblockPage();
            });
        } else {
          let resp = this.ActionUpdateMovimento(this.contaReceber);
          resp
            .then((x) => {
              if (this.contaReceber.anexo) {
                this.ActionCriaAnexo({
                  id_conta_receber: this.contaReceber.id_conta_receber,
                  id_movimentacao: this.contaReceber.id_movimentacao,
                  nome: `${this.contaReceber.nome} - ${this.$moment(
                    this.contaReceber.data_lancamento
                  ).format("DD/MM/YYYY")}`,
                  descricao: this.contaReceber.descricao,
                }).then((r) => {
                  if (r.sucesso == true) {
                    let obj = {
                      id: r.anexo.id_anexo,
                      anexo: this.contaReceber.anexo,
                      op: "contas-receber",
                    };
                    this.ActionUploadAnexo(obj).then((xx) => {
                      this.$swal
                        .fire({
                          icon: xx.sucesso == true ? "success" : "error",
                          title:
                            xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                          text:
                            xx.sucesso == true
                              ? this.opcao == "A"
                                ? `Conta adicionada com sucesso`
                                : "Conta alterada com Sucesso"
                              : xx.error.message,
                        })
                        .then(() => {
                          this.$router.push("/conta-receber");
                        });
                    });
                  }
                });
              } else {
                if (x.sucesso == true) {
                  this.$swal
                    .fire({
                      icon: x.sucesso == true ? "success" : "error",
                      title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                      text:
                        x.sucesso == true
                          ? this.opcao == "A"
                            ? `Conta adicionada com sucesso`
                            : "Conta alterada com Sucesso"
                          : x.error.message,
                    })
                    .then(() => {
                      this.$router.push("/conta-receber");
                    });
                }
              }
            })
            .catch(() => {
              window.KTApp.unblockPage();
            });
        }
      }
    },
    voltar() {
      this.$router.push("/conta-receber");
    },
  },
  components: {
    Conta,
  },
  watch: {
    conciliado(newValue) {
      this.conciliado = newValue;
    },
  },
};
</script>