<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root" style="height:100%;">
    <!--begin::Login-->
    <div
      class="login login-5 login-signin-on d-flex flex-row-fluid"
      id="kt_login"
      style="height:100%;"
    >
      <div
        class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style="background-image: url(assets/media/bg/bg.jpg); height:100%;"
      >
        <div
          class="login-form text-center text-white p-7 position-relative overflow-hidden"
          style="height:100%;"
        >
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#"> <br><br>
              <img
                src="assets/media/logos/logo-default.png"
                class="max-h-75px"
                style="margin-top:40px;"
                alt
              />
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin" id="kt_blockui_content">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">Controle Financeiro</h3>
              <p class="opacity-40">Informe seus dados para acesso:</p>
            </div>
            <form class="form" id="kt_login_signin_form">
              <div class="form-group">
                <input
                  class="form-control h-auto text-white bg-dark-o-100 rounded-pill border-0 py-4 px-8"
                  type="email"
                  placeholder="Email"
                  name="username"
                  autocomplete="off"
                  v-model="form.login"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control h-auto text-white bg-dark-o-100 rounded-pill border-0 py-4 px-8"
                  type="password"
                  placeholder="Senha"
                  name="Senha"
                  v-model="form.senha"
                />
              </div>
              <div
                class="form-group d-flex flex-wrap justify-content-between align-items-center px-8 opacity-60"
              >
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-outline checkbox-white text-white m-0">
                    <input type="checkbox" name="remember" v-model="form.mantem" />
                    <span></span>Lembrar senha
                  </label>
                </div>
                <a
                  class="text-white font-weight-bold"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >Esqueceu sua senha ?</a>
              </div>

              <!-- Modal Esqueceu senha-->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Recuperar senha</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="mb-20" style="color:black;">
                        <br />
                        <h3>Senha esquecida ?</h3>
                        <p class="opacity-60">Digite seu e-mail abaixo para redefinir sua senha.</p>
                        <div align="center">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            v-model="email"
                            style="width:320px;"
                          />
                        </div>
                        <br />
                        <button
                          :disabled="email ==''"
                          type="button"
                          @click="forgotSubmit()"
                          class="btn btn-primary"
                        >Recuperar senha</button>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div class="form-group text-center mt-10" style="margin-bottom:80%;">
                <button
                  :disabled="form.login == '' || form.senha ==''"
                  @click.prevent="submit"
                  id="btnlogin"
                  class="btn btn-pill btn-primary opacity-90 px-15 py-3"
                  style="width: 200px;"
                >Acessar</button>
              </div>
            </form>
          </div>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    form: {
      login: "",
      senha: "",
      mantem: false,
    },
    email: "",
  }),
  methods: {
    ...mapActions("auth", ["ActionDoLogin", "ActionForgotPassword"]),
    forgot() {
      var form = "forgot";
      var _login = window.$("#kt_login");
      var cls = "login-" + form + "-on";
      var forms = "kt_login_" + form + "_form";

      _login.removeClass("login-forgot-on");
      _login.removeClass("login-signin-on");
      _login.removeClass("login-signup-on");

      _login.addClass(cls);

      window.KTUtil.animateClass(
        window.KTUtil.getById(forms),
        "animate__animated animate__backInUp"
      );
    },
    async submit(evt) {
      evt.preventDefault();
      this.$router.push("/");
      try {
        window.KTApp.block("#kt_blockui_content", {
          overlayColor: "#000000",
          state: "primary",
          message: "Acessando...",
        });
        await this.ActionDoLogin(this.form);
        window.KTApp.unblock("#kt_blockui_content");
        this.$router.push("/");
      } catch (error) {
        window.KTApp.unblock("#kt_blockui_content");
        if (error.status != 401) {
          if (error.data) {
            console.log(error.data);
            this.$swal.fire({
              icon: "error",
              title: error.data.error.title || "Oops...",
              text: error.data.error.message,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Ocorreu um erro inesperado, favor contactar o suporte.",
            });
          }
        }
      }
    },
    async forgotSubmit() {
      this.ActionForgotPassword({ email: this.email }).then((r) => {
        if (r.sucesso == true) {
          this.$swal.fire({
            icon: "success",
            title: "Sucesso",
            text:
              "Solicitação de nova senha realizada com sucesso, em breve você receberá um email!",
          });
        }
      });
    },
  },
};
</script>