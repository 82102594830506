<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="[]"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="card card-custom">
          <div
            class="alert alert-custom alert-white alert-shadow fade show gutter-b"
            role="alert"
          >
            <div class="alert-icon">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <div class="alert-text">
              <div
                class="input-daterange input-group col-lg-9"
                id="kt_datepicker_5"
              >
                <input
                  type="month"
                  v-model="dataInicio"
                  class="form-control"
                  name="start"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="la la-ellipsis-h"></i>
                  </span>
                </div>
                <input
                  type="month"
                  v-model="dataFim"
                  class="form-control"
                  name="end"
                />

                <div
                  class="checkbox-lg"
                  style="margin-top: 10px; margin-left: 20px; font-size: 13px"
                >
                  <input type="checkbox" v-model="futuro" name="Checkboxes2" />
                  Laçamentos Futuros
                </div>
                <!-- <div
                  class="checkbox-lg"
                  style="margin-top: 10px; margin-left: 20px; font-size: 13px"
                >
                  <input
                    type="checkbox"
                    v-model="contabiliza"
                    name="Checkboxes2"
                  />
                  Laçamentos Não Contabilizados
                </div> -->
              </div>
            </div>
          </div>

          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <img src="assets/media/svg/icons/Shopping/Chart-bar1.svg" />
                Fluxo de Caixa Mensal
              </h3>
            </div>
          </div>
          <div class="card-body">
            <!--begin::Chart-->
            <apexchart
              height="350"
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
            <!--end::Chart-->
          </div>

          <hr />
          <div class="card-body">
            <!--begin: Datatable-->
            <div style="text-align: right">
              <xlsx-workbook>
                <xlsx-sheet
                  :collection="sheet.data"
                  v-for="sheet in sheets"
                  :key="sheet.name"
                  :sheet-name="sheet.name"
                />
                <xlsx-download :filename="nomeArq">
                  <button type="button" class="btn btn-outline-info btn-sm">
                    <i class="fa fa-cog icon-lg"></i>
                    EXPORTAR
                  </button>
                </xlsx-download>
              </xlsx-workbook>
            </div>
            <table
              class="table table-bordered table-hover table-checkable"
              id="kt_datatable"
              style="margin-top: 13px !important"
            >
              <thead>
                <tr>
                  <th>MÊs/Ano</th>
                  <th>Recebimentos</th>
                  <th>Pagamentos</th>
                  <th>Saldo Final</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dados" :key="index">
                  <td>{{ item.mes }}</td>
                  <td>
                    <b style="color: blue">
                      {{
                        Number(item.recebido.valor).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                    <br />
                  </td>
                  <td>
                    <b style="color: red">
                      {{
                        Number(item.pago.valor).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                    <br />
                  </td>
                  <td>
                    <b style="color: green">
                      {{
                        Number(item.saldo.valor).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                    <br />
                  </td>
                </tr>
                <tr style="background-color: rgba(190, 190, 190, 0.2)">
                  <td>
                    <b>TOTAL</b>
                  </td>
                  <td>
                    <b style="color: green">
                      {{
                        recebido.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                  </td>
                  <td>
                    <b style="color: red">
                      {{
                        pago.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                  </td>
                  <td>
                    <b style="color: blue">
                      {{
                        saldo.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--end: Datatable-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
// Shared Colors Definition
const primary = "#1BC5BD";
const success = "#f00000";
// const info = "#8950FC";
const warning = "#174ade";
// const danger = "#F64E60";
// const mes = [
//   "Jan",
//   "Fev",
//   "Mar",
//   "Abr",
//   "Mai",
//   "Jun",
//   "Jul",
//   "Ago",
//   "Set",
//   "Out",
//   "Nov",
//   "Dez",
// ];
import SubHeader from "../../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
import {
  XlsxDownload,
  XlsxWorkbook,
  XlsxSheet,
} from "vue-xlsx/dist/vue-xlsx.es.js";

export default {
  components: {
    SubHeader,
    XlsxDownload,
    XlsxWorkbook,
    XlsxSheet,
  },
  data: () => ({
    titulo: "Fluxo Mensal",
    dataInicio: null,
    dataFim: null,
    dados: [],
    options: {},
    series: [],
    futuro: false,
    contabiliza: false,
  }),
  computed: {
    ...mapState("auth", ["empresa"]),
    nomeArq() {
      return `${process.env.VUE_APP_NOME}_Fluxo Mensal_${this.$moment(
        this.dataInicio
      ).format("DD_MM_YYYY_HHmmss")}_${this.$moment(this.dataFim).format(
        "DD_MM_YYYY_HHmmss"
      )}_${this.$moment(new Date()).format("DD_MM_YYYY_HHmmss")}.xlsx`;
    },
    pago() {
      return this.dados.length == 0
        ? 0
        : this.dados.reduce((acc, value) => {
            return acc + Number(value.pago.valor);
          }, 0);
    },
    recebido() {
      return this.dados.length == 0
        ? 0
        : this.dados.reduce((acc, value) => {
            return acc + Number(value.recebido.valor);
          }, 0);
    },
    saldo() {
      return this.dados.length == 0
        ? 0
        : this.dados.reduce((acc, value) => {
            return acc + Number(value.saldo.valor);
          }, 0);
    },
    sheets() {
      let arrays = [];
      let obj = { name: "Fluxo Mensal", data: [] };
      obj.data = this.dados.map((x) => {
        return {
          "Mês/Ano": x.mes,
          Recebimentos: x.recebido.valor,
          Pagamentos: x.pago.valor,
          "Saldo Final": x.saldo.valor,
        };
      });
      arrays.push(obj);
      return arrays;
    },
  },
  mounted() {
    let data = new Date();
    data = new Date(data.getFullYear(), data.getMonth(), 1);
    let data2 = new Date(data.getFullYear(), data.getMonth() + 1, 1);
    data2 = data2.setDate(data2.getDate() - 1);
    this.dataInicio = this.$moment(data).format("YYYY-MM");
    this.dataFim = this.$moment(data2).format("YYYY-MM");
    this.load();
  },
  methods: {
    ...mapActions("relatorio", ["ActionGetFluxoMensal"]),
    seriesFluxo(dados) {
      return [
        {
          name: "Recebimentos",
          data: dados.map((x) => x.recebido.valor), // [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Pagamentos",
          data: this.dados.map((x) => x.pago.valor),
        },
        {
          name: "Saldo Final",
          data: this.dados.map((x) => x.saldo.valor),
        },
      ];
    },
    optionsFluxo(categorias) {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categorias,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          title: {
            text: "Valores (R$)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
        colors: [primary, success, warning],
      };
    },

    load() {
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });

      let obj = {
        mesInicio: this.dataInicio,
        mesFim: this.dataFim,
        efetivado: !this.futuro,
        contabiliza: this.contabiliza ? this.contabiliza : undefined,
      };
      this.ActionGetFluxoMensal(obj).then((r) => {
        this.dados = r;
        this.dados.forEach((el) => {
          el.data = this.$moment(el.data).format("DD/MM/YYYY");
        });
        this.options = this.optionsFluxo(this.dados.map((x) => x.mes));
        this.series = this.seriesFluxo(this.dados);
        console.log(this.dados);
        console.log(this.options);
        console.log(this.series);
        window.KTApp.unblockPage();
        if (this.dados.length == 0) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Não existe dados para o relatório",
          });
          return;
        }
      });
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      this.load();
    },
    dataInicio(nValue) {
      this.dataInicio = nValue;
      this.load();
    },
    futuro(nValue) {
      this.futuro = nValue;
      this.load();
    },
    contabiliza(nValue) {
      this.contabiliza = nValue;
      this.load();
    },
  },
};
</script>